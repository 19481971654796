import { Account } from "@tesseract/core";
import { textUsApiV4 } from "services";

export const fetchKeywordsCounts = (
  currentAccount: Account.Raw,
  mine: boolean,
  userId: string,
): Promise<Response> => {
  // Syntax must be exact for ransack gem on BE
  const ransackKey = mine ? "ownerIdEq" : "ownerIdNotEq";

  return textUsApiV4.get(
    `/${currentAccount.slug}/automation_keywords/counts?q[${ransackKey}]=${userId}`,
  );
};
