import { Box, Chip, Link, List, ListItem, Typography } from "@mui/material";
import { ComponentProps } from "react";
import { capitalize, get } from "lodash";
import { format } from "date-fns";
import { ContactSyncDetailsProps } from "./types";
import { generateContactSyncDetails } from "./utils/generateContactSyncDetials";

const listItemProps: ComponentProps<typeof ListItem> = {
  sx: {
    display: "block",
  },
  disablePadding: true,
};

export function ContactSyncDetails({
  contactSyncData,
}: ContactSyncDetailsProps) {
  const contactType = Object.keys(contactSyncData)[0];

  const formattedContactSyncData = generateContactSyncDetails(
    contactSyncData[contactType],
  );

  const getApplicationJobName = () => {
    if (formattedContactSyncData.application) {
      if (formattedContactSyncData?.application?.job?.[0]) {
        return formattedContactSyncData?.application?.job[0].name;
      }
      return null;
    }
    return null;
  };

  const getStage = () => {
    if (formattedContactSyncData.stage) {
      const stage = formattedContactSyncData.stage.replaceAll("_", " ");
      return capitalize(stage.toLowerCase() ?? "");
    }
    // Get stage field from application if there is not a field value
    if (formattedContactSyncData.application) {
      if (formattedContactSyncData?.application?.current_stage?.[0]) {
        return formattedContactSyncData.application.current_stage[0].name;
      }
      return null;
    }
    return null;
  };

  const formatStatusString = () => {
    return capitalize(formattedContactSyncData?.status?.toLowerCase() ?? "");
  };

  const formatBirthday = () => {
    return format(
      new Date(formattedContactSyncData.birthday ?? ""),
      "MMMM d, yyyy",
    );
  };

  const getEmail = () => {
    if (Array.isArray(formattedContactSyncData.emails)) {
      return formattedContactSyncData.emails[0];
    }
    return formattedContactSyncData.emails;
  };

  const renderLocationField = () => {
    if (Array.isArray(formattedContactSyncData.locations)) {
      return formattedContactSyncData.locations.map((location: any) => {
        if (typeof location === "string") {
          return (
            <ListItem {...listItemProps} key={location}>
              <Typography variant="subtitle2">Location</Typography>
              <Typography variant="body1">{location}</Typography>
            </ListItem>
          );
        }
        if (typeof location === "object") {
          return (
            <ListItem {...listItemProps} key={location}>
              <Typography variant="subtitle2">Location</Typography>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="body1">
                  {location?.street_address ?? ""}
                </Typography>
                <Typography variant="body1">
                  {location?.city ?? ""}, {location?.state ?? ""},{" "}
                  {location?.zip_code.split("-")[0] ?? ""}
                </Typography>
              </Box>
            </ListItem>
          );
        }
        return null;
      });
    }
    if (typeof formattedContactSyncData.locations === "string") {
      return (
        <ListItem {...listItemProps}>
          <Typography variant="subtitle2">Location</Typography>
          <Typography variant="body1">
            {formattedContactSyncData.locations}
          </Typography>
        </ListItem>
      );
    }
    return null;
  };

  return (
    <List
      disablePadding
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr",
        gap: 2,
      }}
    >
      {formattedContactSyncData.id ? (
        <ListItem {...listItemProps}>
          <Typography variant="subtitle2">{contactType} ID</Typography>
          <Typography variant="body1">{formattedContactSyncData.id}</Typography>
        </ListItem>
      ) : null}
      {formattedContactSyncData.status ? (
        <ListItem {...listItemProps}>
          <Typography variant="subtitle2">{contactType} status</Typography>
          <Chip
            sx={(theme) => {
              return {
                backgroundColor: theme.palette.secondary.main,
                color: "white",
                marginLeft: 0,
              };
            }}
            size="small"
            variant="filled"
            label={formatStatusString()}
          />
        </ListItem>
      ) : null}
      {getApplicationJobName() ? (
        <ListItem {...listItemProps}>
          <Typography variant="subtitle2">Job</Typography>
          <Typography variant="body1">{getApplicationJobName()}</Typography>
        </ListItem>
      ) : null}
      {getStage() ? (
        <ListItem {...listItemProps}>
          <Typography variant="subtitle2">Stage</Typography>
          <Chip
            sx={{
              marginLeft: 0,
            }}
            size="small"
            variant="filled"
            label={getStage()}
          />
        </ListItem>
      ) : null}
      {formattedContactSyncData.owner ? (
        <ListItem {...listItemProps}>
          <Typography variant="subtitle2">Owner</Typography>
          <Typography variant="body1">
            {formattedContactSyncData.owner}
          </Typography>
        </ListItem>
      ) : null}
      {formattedContactSyncData.title ? (
        <ListItem {...listItemProps}>
          <Typography variant="subtitle2">Title</Typography>
          <Typography variant="body1">
            {formattedContactSyncData.title}
          </Typography>
        </ListItem>
      ) : null}
      {formattedContactSyncData.birthday ? (
        <ListItem {...listItemProps}>
          <Typography variant="subtitle2">Birthday</Typography>
          <Typography variant="body1">{formatBirthday()}</Typography>
        </ListItem>
      ) : null}
      {formattedContactSyncData.locations ? renderLocationField() : null}
      {formattedContactSyncData.emails ? (
        <ListItem {...listItemProps}>
          <Link
            href={`mailto:${getEmail()}`}
            target="_blank"
            rel="noreferrer"
            sx={(theme) => {
              return {
                color: theme.palette.text.hyperlink,
                textDecoration: "underline",
                "&:hover": {
                  cursor: "pointer",
                },
              };
            }}
            variant="body1"
          >
            {getEmail()}
          </Link>
        </ListItem>
      ) : null}
    </List>
  );
}
