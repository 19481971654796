import { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Field } from "formik";
import { SubKeywordFormProps } from "../types";
import KeywordFormSequenceSelect from "./KeywordFormSequenceSelect";
import AutomaticMessageIndicator from "components/AutomaticMessageIndicator";
import MessageInputFormRow from "components/MessageInputFormRow";
import { useCurrentAccount } from "hooks";
import { useAutomationNavLinks } from "features/Automation/hooks/useAutomationNavLinks";

const MessageWrapper = styled(Box)(({ theme }) => {
  return {
    margin: "1rem 0 1rem 0",
    border: `1px solid ${theme.palette.customColors.enabledBorder}`,
    borderRadius: "8px",
  };
});

function SubKeywordForm({
  index,
  errors,
  values,
  setFieldValue,
  touched,
  sequences,
}: SubKeywordFormProps) {
  const account = useCurrentAccount();
  const [_sequenceId, setSequenceId] = useState("");
  const [shortenedLink, setShortenedLink] = useState({
    fullLink: "",
    shortLink: account.shortenedLinkPreviewUrl,
  });
  const keywordField = `keywordAnswers[${index}][keyword]`;

  const onClick = (event) => {
    event.preventDefault();
    const updatedValues = values.keywordAnswers.filter((_keywordAnswer, i) => {
      return i !== index;
    });

    setFieldValue("keywordAnswers", updatedValues);
  };

  const handleChange = (event) => {
    setFieldValue(keywordField, event.target.value.toUpperCase());
  };

  const { sequencesEnabled } = useAutomationNavLinks();

  return (
    <Accordion
      square={false}
      sx={(theme) => {
        return {
          width: "720px",
          boxShadow: "none",
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: "4px",
          marginLeft: "1rem",
          backgroundColor: theme.palette.customColors.backgrounds.secondary,
          "&:before": {
            display: "none",
          },
        };
      }}
      disableGutters
      defaultExpanded
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          flexDirection: "row-reverse",
          height: "56px",
          paddingRight: "8px",
          "& .MuiAccordionSummary-content": {
            justifyContent: "space-between",
          },
        }}
      >
        <Typography
          variant="h5"
          data-testid="keyword-answer-accordion"
          sx={{ marginLeft: "1rem", alignSelf: "center" }}
        >
          Keyword answer
        </Typography>
        <IconButton onClick={onClick}>
          <CloseIcon />
        </IconButton>
      </AccordionSummary>
      <AccordionDetails
        sx={(theme) => {
          return {
            padding: "1rem",
            borderTop: `1px solid ${theme.palette.divider}`,
            backgroundColor: theme.palette.background.paper,
            borderRadius: "0 0 4px 4px",
          };
        }}
      >
        <Box
          sx={{
            marginBottom: "1rem",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="span" sx={{ alignSelf: "center" }}>
            If a contact replies with...
          </Typography>
          <Field name={keywordField} type="text" style={{ display: "inline" }}>
            {({ field }) => {
              return (
                <TextField
                  {...field}
                  fieldName={field.name}
                  hiddenLabel
                  autoFocus
                  value={values.keywordAnswers[index].keyword || ""}
                  onChange={handleChange}
                  data-testid="keyword-trigger"
                  error={touched.title && !!errors.title}
                  helperText={touched.title && errors.title}
                  id="keyword-trigger"
                  placeholder="Keyword"
                  type="text"
                  variant="outlined"
                  sx={{ width: "482px" }}
                />
              );
            }}
          </Field>
        </Box>

        <Typography>Then send this message:</Typography>
        <MessageWrapper>
          <MessageInputFormRow
            data-testid="keyword-answer-auto-response"
            currentAccount={account}
            errors={errors}
            values={values}
            fieldName={`keywordAnswers[${index}][responseBody]`}
            isDisabled={false}
            hideUserPersonalization={false}
            placeholder="Message"
            maxChars={420}
            setFieldValue={setFieldValue}
            setShortenedLink={setShortenedLink}
            shortenedLink={shortenedLink}
            touched={touched}
            messageFieldOutlineActive={false}
            formPadding="12px 16px 8px 16px"
            segmentCountActive={false}
          />
        </MessageWrapper>
        <AutomaticMessageIndicator />
        {sequencesEnabled && (
          <KeywordFormSequenceSelect
            account={account}
            keyword={values.keywordAnswers[index].keyword}
            enrollInSequence={values.keywordAnswers[index].enrollInSequence}
            sequenceId={values.keywordAnswers[index].sequenceId}
            setSequenceId={setSequenceId}
            sequenceFieldName={`keywordAnswers[${index}][sequenceId]`}
            enrollInSequenceFieldName={`keywordAnswers[${index}][enrollInSequence]`}
            setFieldValue={setFieldValue}
            sequences={sequences}
            searchWidth="498px"
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
}

export default SubKeywordForm;
