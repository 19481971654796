import { useEffect, ChangeEvent, useState } from "react";

import {
  Box,
  FormControlLabel,
  Switch,
  Tab,
  Tabs,
  Tooltip,
  Button,
} from "@mui/material";
import { SnackbarKey, SnackbarMessage, useSnackbar } from "notistack";

import { IndividualSequenceTabsProps } from "./types";
import {
  INDIVIDUAL_SEQUENCE_TABS,
  SEQUENCE_STATES,
} from "features/Sequences/screens/IndividualSequenceOverview/constants";

import { Snackbar } from "components/Snackbar";
import {
  checkIfNeedsScheduling,
  checkWithinFiveMinutes,
} from "features/Sequences/screens/IndividualSequenceOverview/utils";

function IndividualSequenceTabs({
  isLoading,
  selectedTab,
  sequence,
  sequenceSteps,
  handleActivateSequence,
  handleDeactivateSequence,
  handleChange,
  refreshPage,
  handleEditStep,
}: IndividualSequenceTabsProps) {
  const { overview, contacts, settings } = INDIVIDUAL_SEQUENCE_TABS;
  const { active, archive } = SEQUENCE_STATES;

  const [isSequenceActive, setIsSequenceActive] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const needsScheduling: boolean = checkIfNeedsScheduling(sequenceSteps);

  const noSteps = sequence.stepCount === 0;

  const getTooltipText = () => {
    if (noSteps) {
      return "1 step is required to switch active";
    }

    if (needsScheduling) {
      return "Update step delays";
    }

    return "";
  };

  const renderSnackbar = (key: SnackbarKey, message: SnackbarMessage) => {
    return (
      <Snackbar
        id={key}
        message={message}
        variant="error"
        action={
          <Button onClick={handleEditStep} sx={{ color: "white" }}>
            Update
          </Button>
        }
      />
    );
  };

  const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const isFirstStepTooSoon = checkWithinFiveMinutes(
      sequenceSteps[0]?.absoluteTime,
    );

    if (isFirstStepTooSoon) {
      return enqueueSnackbar(
        "Step #1 is less than 5 minutes from now. Please update step date and time.",
        {
          content: renderSnackbar,
        },
      );
    }
    setIsSequenceActive(event.target.checked);

    const finallyCallback = () => {
      refreshPage();
    };

    if (event.target.checked) {
      return handleActivateSequence(sequence, {
        showSnackbar: true,
        finallyCallback,
      });
    }
    return handleDeactivateSequence(sequence, {
      showSnackbar: true,
      finallyCallback,
    });
  };

  useEffect(() => {
    setIsSequenceActive(sequence.state === active);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sequence.state]);

  return (
    <Box display="flex" flexDirection="row" marginLeft="1.5rem">
      {sequence.state === archive ? null : (
        <Tooltip title={getTooltipText()}>
          <FormControlLabel
            control={
              <Switch
                checked={isSequenceActive}
                color="secondary"
                disabled={noSteps || needsScheduling}
                onChange={handleSwitchChange}
                data-testid="sequence-activated-inactivated"
              />
            }
            label={isSequenceActive ? "Active" : "Inactive"}
            data-testid="sequence-toggle-label"
            componentsProps={{ typography: { variant: "body1" } }}
          />
        </Tooltip>
      )}
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        aria-label="Individual Sequence Tab List"
        sx={{ marginLeft: "0.5rem" }}
      >
        {[overview, contacts].map((tab) => {
          // TODO: Add settings to above array when feature flag is deleted
          return (
            <Tab
              data-testid={tab}
              key={tab}
              disabled={isLoading}
              label={tab}
              sx={{ marginLeft: "0.5rem", marginRight: "0.5rem" }}
            />
          );
        })}
        <Tab
          disabled={isLoading}
          label={settings}
          data-testid="Settings"
          sx={{ marginLeft: "0.5rem", marginRight: "0.5rem" }}
        />
      </Tabs>
    </Box>
  );
}
export { IndividualSequenceTabs };
