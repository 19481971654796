import assert from "assert";
import { userManager } from "@tesseract/core";
import { headers } from "constants/apiConstants";
import type { Account } from "models/Account";

export const fetchSequences = async (
  currentAccount: Account,
  filter?: {
    searchTerm?: string;
    selectedNavLinkKey?: "userIdEq" | "userIdNotEq";
    strippedUserId?: string;
    activeOnly?: boolean;
  },
): Promise<Response> => {
  const user = await userManager.getUser();
  assert(user?.access_token);

  let url = `/api/v4/${currentAccount.slug}/sequences`;

  // Adds query params if filters were passed.
  if (filter) {
    const { searchTerm, selectedNavLinkKey, strippedUserId } = filter;

    const nameContParams = `q[nameCont]=${searchTerm}`;
    const selectedNavLinkParams = `q[${selectedNavLinkKey}]=${strippedUserId}`;
    const stateParams = `q[stateEq]=active`;
    let paramsString = "";

    if (searchTerm) {
      paramsString += nameContParams;
    }

    if (selectedNavLinkKey && strippedUserId) {
      if (paramsString) {
        paramsString += "&";
      }
      paramsString += selectedNavLinkParams;
    }

    if (filter.activeOnly) {
      if (paramsString) {
        paramsString += "&";
      }
      paramsString += stateParams;
    }

    url += `?${paramsString}`;
  }

  const request = new Request(url, {
    method: "GET",
    headers: {
      ...headers,
      Authorization: `Bearer ${user?.access_token}`,
      "Content-Type": "application/json",
    },
  });

  return fetch(request);
};
