import {
  Box,
  Grid2 as Grid,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import { IntegrationCard } from "../IntegrationCard";
import { CustomIntegrationDialog } from "../CustomIntegrationDialog";
import { OauthIntegrationDialog } from "../OauthIntegrationDialog/OauthIntegrationDialog";
import { AvailableIntegrationsProps } from "./types";
import Loader from "components/Loader";
import { IntegrationProvider } from "models/IntegrationProvider";
import { useDebouncedSearch } from "hooks";
import { SearchBox } from "components/SearchBox";

export function AvailableIntegrations({
  allIntegrations,
  connectedIntegrations,
  currentAccount,
  selectedIntegration,
  setSelectedIntegration,
}: AvailableIntegrationsProps) {
  const [availableIntegrations, setAvailableIntegrations] = useState<
    IntegrationProvider[]
  >([]);

  const [customAuthorizationDialogOpen, setCustomAuthorizationDialogOpen] =
    useState<boolean>(false);

  const [oauthDialogOpen, setOauthDialogOpen] = useState<boolean>(false);

  const { searchTerm, handleSearch, handleClear } = useDebouncedSearch();

  const mobileScreen = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down("sm");
  });

  useEffect(() => {
    if (allIntegrations.length > 0 && connectedIntegrations.length > 0) {
      const filteredIntegrations = [...allIntegrations];
      connectedIntegrations.forEach((connectedIntegration) => {
        const index = filteredIntegrations.findIndex((integration) => {
          return integration.providerName === connectedIntegration.providerName;
        });
        filteredIntegrations.splice(index, 1);
      });
      return setAvailableIntegrations(filteredIntegrations);
    }
    return setAvailableIntegrations(allIntegrations);
  }, [allIntegrations, connectedIntegrations]);

  const renderAvailableIntegrationCards = () => {
    const integrationsToRender = searchTerm
      ? availableIntegrations.filter((integration) => {
          return integration.providerName
            .toLowerCase()
            .includes(searchTerm.toLowerCase());
        })
      : availableIntegrations;
    return integrationsToRender.map((integration) => {
      if (integration.enabled === false) return null;
      return (
        <IntegrationCard
          integration={integration}
          setCustomAuthorizationDialogOpen={setCustomAuthorizationDialogOpen}
          setOauthDialogOpen={setOauthDialogOpen}
          setSelectedIntegration={setSelectedIntegration}
          key={`${integration.provider}-${integration.category || ""}`}
        />
      );
    });
  };

  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: mobileScreen ? null : "center",
            flexDirection: mobileScreen ? "column" : "row",
          }}
        >
          <Typography variant="h4">Available</Typography>
          <SearchBox
            value={searchTerm}
            onChange={handleSearch}
            onClear={handleClear}
            placeholder="Search"
            sx={{ paddingTop: "8px", maxWidth: "288px", minWidth: "256px" }}
          />
        </Box>
        <Loader
          isLoading={availableIntegrations.length === 0}
          aria-label="Page is Loading"
        />
        {availableIntegrations.length > 0 ? (
          <Grid
            container
            spacing={2}
            sx={{
              marginTop: "24px",
              marginBottom: "32px",
            }}
          >
            {renderAvailableIntegrationCards()}
            <IntegrationCard
              integration={{
                provider: "webhook",
                providerName: "Add webhook",
                strategy: "custom",
                config: {
                  baseUrl: "",
                },
              }}
              setCustomAuthorizationDialogOpen={
                setCustomAuthorizationDialogOpen
              }
              setSelectedIntegration={setSelectedIntegration}
            />
          </Grid>
        ) : null}
      </Box>

      <CustomIntegrationDialog
        currentAccount={currentAccount}
        integration={selectedIntegration}
        open={customAuthorizationDialogOpen}
        setCustomAuthorizationDialogOpen={setCustomAuthorizationDialogOpen}
        setSelectedIntegration={setSelectedIntegration}
      />

      <OauthIntegrationDialog
        currentAccount={currentAccount}
        integration={selectedIntegration}
        open={oauthDialogOpen}
        setOauthDialogOpen={setOauthDialogOpen}
        setSelectedIntegration={setSelectedIntegration}
      />
    </>
  );
}
