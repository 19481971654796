import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";

function AddKeywordAnswerButton({ onClick }) {
  return (
    <Button
      startIcon={<AddIcon />}
      color="primary"
      sx={{
        marginLeft: "6px",
        height: "36px",
        width: "216px",
        "&:hover": { backgroundColor: "transparent" },
      }}
      data-testid="add-keyword-answer-button"
      onClick={onClick}
    >
      Add Keyword Answer
    </Button>
  );
}

export default AddKeywordAnswerButton;
