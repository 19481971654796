import { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

interface SubKeyword {
  keyword: string;
  responseBody: string;
  timesReceived: number;
  answeredCount: number;
  clickedLinkCount: number;
}

function SubKeywordCard({
  hasLink,
  subKeyword,
}: {
  subKeyword: SubKeyword;
  hasLink: (messageBody: string) => boolean;
}) {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  const percentAnswered =
    subKeyword.timesReceived === 0
      ? 0
      : (subKeyword.answeredCount / subKeyword.timesReceived) * 100;

  const percentClicked =
    subKeyword.timesReceived === 0
      ? 0
      : (subKeyword.clickedLinkCount / subKeyword.timesReceived) * 100;

  const subKeywordHasLink = hasLink(subKeyword.responseBody);

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      disableGutters
      sx={(theme) => {
        return {
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: "4px 4px 4px 4px",
          backgroundColor: theme.palette.customColors.backgrounds.secondary,
          boxShadow: "none",
          width: "100%",
          maxWidth: "740px",
          height: "auto",
          "&::before": {
            display: "none",
          },
        };
      }}
    >
      <AccordionSummary
        expandIcon={<KeyboardArrowDownIcon />}
        sx={(theme) => {
          return {
            height: "40px",
            padding: "8px",
            display: "flex",
            flexDirection: "row-reverse",
            "&.Mui-expanded": {
              borderBottom: `1px solid ${theme.palette.divider}`,
            },
          };
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Typography variant="h6" paddingLeft="8px" alignItems="center">
            Keyword answer: {subKeyword.keyword}
          </Typography>
          <Typography
            variant="caption"
            sx={(theme) => {
              return {
                alignItems: "flex-end",
                color: theme.palette.text.secondary,
                display: "flex",
                fontSize: "13px",
                padding: "16px 8px",
                whiteSpace: "nowrap",
              };
            }}
          >
            {subKeyword.timesReceived} viewed
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails
        sx={(theme) => {
          return {
            backgroundColor: theme.palette.background.paper,
            display: expanded ? "block" : "none",
            transition: "display 0.3s ease",
            height: "62px",
            padding: "4px 16px",
            borderRadius: "4px 4px 4px 4px",
          };
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            height: "52px",
            overflow: "hidden",
            width: "100%",
          }}
        >
          <Typography
            variant="body1"
            alignItems="center"
            sx={{
              paddingBottom: 0,
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
              display: "-webkit-box",
              fontSize: "16px",
              lineHeight: "1.5",
              overflow: "hidden",
              width: "100%",
              paddingRight: "24px",
              color: "text.secondary",
            }}
          >
            {subKeyword.responseBody}
          </Typography>
          <Box display="flex">
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "16px",
                paddingRight: subKeywordHasLink ? "16px" : "0px",
              }}
            >
              <Box textAlign="center">
                <Typography
                  sx={(theme) => {
                    return {
                      color: theme.palette.primary.main,
                      fontSize: "16px",
                      fontWeight: 700,
                    };
                  }}
                >
                  {percentAnswered}%
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={(theme) => {
                    return {
                      alignSelf: "stretch",
                      color: theme.palette.text.secondary,
                      whiteSpace: "nowrap",
                      fontWeight: 500,
                    };
                  }}
                >
                  Responded
                </Typography>
              </Box>
            </Box>
            {subKeywordHasLink ? (
              <>
                <Divider
                  orientation="vertical"
                  variant="middle"
                  sx={{ height: "8px", margin: "16px 0px" }}
                  flexItem
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "16px",
                    paddingLeft: "16px",
                  }}
                >
                  <Box textAlign="center">
                    <Typography
                      sx={(theme) => {
                        return {
                          color: theme.palette.primary.main,
                          fontSize: "16px",
                          fontWeight: 700,
                        };
                      }}
                    >
                      {percentClicked}%
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={(theme) => {
                        return {
                          alignSelf: "stretch",
                          color: theme.palette.text.secondary,
                          whiteSpace: "nowrap",
                          fontWeight: 500,
                        };
                      }}
                    >
                      Clicked links
                    </Typography>
                  </Box>
                </Box>
              </>
            ) : null}
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

export default SubKeywordCard;
