/* eslint-disable jsx-a11y/anchor-is-valid */
/* Using component="button" in a MUI Link adds link styles to a button */

import { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Link,
} from "@mui/material";
import { clamp } from "lodash";
import ViewMoreRow from "./ViewMoreRow";
import Logo from "components/Logo";

const getPhoneText = (phoneNumbers) => {
  if (phoneNumbers.length === 0) {
    return "Non-messaging admin account";
  }
  if (phoneNumbers.length === 1) {
    return phoneNumbers[0];
  }
  return `${phoneNumbers.length} Numbers`;
};

// Props come from react-vtree FixedSizeTree component
function AccountNode({ data, isOpen, style, toggle }) {
  const {
    accountHierarchyId,
    brandInformation,
    children,
    effectiveRole,
    fetchAccountCollectionRequest,
    fetchAccountHierarchyRequest,
    id,
    name,
    nameOnly,
    nestingLevel,
    phoneNumbers,
    setCurrentAccount,
    type,
    users,
  } = data;

  const childrenId = children?.id;
  const totalCount = data?.childrenCount || 0;
  const isLeaf = totalCount === 0;
  const isClosed = children?.members?.length !== totalCount && totalCount < 30;

  const toggleHandler = useCallback(() => {
    if (nestingLevel > 0 || type !== "partner") {
      fetchAccountCollectionRequest(childrenId);
    } else {
      fetchAccountHierarchyRequest(accountHierarchyId);
    }
    toggle();
  }, [
    accountHierarchyId,
    childrenId,
    fetchAccountCollectionRequest,
    fetchAccountHierarchyRequest,
    nestingLevel,
    toggle,
    type,
  ]);

  const icon = useMemo(() => {
    const showLoader = isOpen && children?.members?.length === 0;
    if (showLoader && !isClosed)
      return (
        <Logo
          key={id}
          animate
          color="transparent"
          dotColor="currentColor"
          width={18}
        />
      );
    if (isOpen && !isClosed) return <ExpandMoreIcon key={id} />;
    return <ChevronRightIcon />;
  }, [children?.members?.length, id, isClosed, isOpen]);

  if (type === "load_more") {
    return <ViewMoreRow key={id} data={data} style={style} />;
  }

  return (
    <ListItem
      component="div"
      sx={{
        ...style,
        backgroundColor:
          data.index % 2 === 0 ? "transparent" : "rgba(0, 0, 0, 0.04)",
        height: "52px",
        padding: 0,
        paddingLeft: `${clamp(nestingLevel * 32, 0, 32 * 4) + 16}px`,
      }}
      key={id}
      onClick={isLeaf ? undefined : toggleHandler}
    >
      {isLeaf ? <Box width="32px" /> : <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText
        sx={{
          padding: 0,
        }}
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          <Box
            key="name"
            sx={{
              flex: "auto",
              flexGrow: 1,
              flexShrink: 1,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              textAlign: "left",
            }}
            component="div"
          >
            {name}
          </Box>
          {!nameOnly && (
            <>
              <Box
                key="numbers"
                sx={{
                  flex: "188px",
                  flexGrow: 0,
                  flexShrink: 0,
                  textAlign: "left",
                }}
              >
                {getPhoneText(phoneNumbers)}
              </Box>
              <Box
                key="children"
                sx={{
                  flex: "96px",
                  flexGrow: 0,
                  flexShrink: 0,
                  textAlign: "left",
                }}
              >
                {totalCount}
              </Box>
            </>
          )}
          {!nameOnly && !brandInformation && (
            <>
              <Box
                key="users"
                sx={{
                  flex: "96px",
                  flexGrow: 0,
                  flexShrink: 0,
                  textAlign: "left",
                }}
              >
                {users.totalItems ?? 0}
              </Box>
              <Box
                key="manage"
                sx={{
                  flex: "112px",
                  flexGrow: 0,
                  flexShrink: 0,
                  textAlign: "left",
                }}
              >
                {effectiveRole && (
                  <Link
                    component="button"
                    color="inherit"
                    onClick={() => {
                      return setCurrentAccount(id);
                    }}
                  >
                    View
                  </Link>
                )}
              </Box>
            </>
          )}
        </Stack>
      </ListItemText>
    </ListItem>
  );
}

AccountNode.propTypes = {
  data: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  style: PropTypes.object.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default AccountNode;
