import { useState } from "react";
import { Box, TextField, Typography, styled } from "@mui/material";
import { Field } from "formik";
import KeywordFormSequenceSelect from "./KeywordFormSequenceSelect";
import { RootKeywordFormProps } from "features/Keywords/types";
import AutomaticMessageIndicator from "components/AutomaticMessageIndicator";
import MessageInputFormRow from "components/MessageInputFormRow";
import { useCurrentAccount } from "hooks";
import { useAutomationNavLinks } from "features/Automation/hooks/useAutomationNavLinks";

const MessageWrapper = styled(Box)(({ theme }) => {
  return {
    margin: "1rem 0 1rem 0",
    border: `1px solid ${theme.palette.customColors.enabledBorder}`,
    borderRadius: "8px",
  };
});

function RootKeywordForm({
  errors,
  touched,
  values,
  setFieldValue,
  sequences,
}: RootKeywordFormProps) {
  const account = useCurrentAccount();
  const [shortenedLink, setShortenedLink] = useState({
    fullLink: "",
    shortLink: account.shortenedLinkPreviewUrl,
  });

  const [_sequenceId, setSequenceId] = useState("");

  const handleChange = (event) => {
    setFieldValue("keyword", event.target.value.toUpperCase());
  };

  const { sequencesEnabled } = useAutomationNavLinks();

  return (
    <Box>
      <Typography component="h4" variant="h4">
        Keyword Trigger
      </Typography>
      <Box
        sx={(theme) => {
          return {
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: "4px",
            marginTop: "1rem",
            width: "48rem",
          };
        }}
      >
        <Box sx={{ margin: "1rem" }}>
          <Field name="keyword" type="text">
            {({ field }) => {
              return (
                <TextField
                  {...field}
                  fieldName={field.name}
                  hiddenLabel
                  autoFocus
                  value={values.keyword}
                  onChange={handleChange}
                  data-testid="keyword-trigger"
                  error={touched.title && !!errors.title}
                  helperText={touched.title && errors.title}
                  id="keyword-trigger"
                  placeholder="Keyword"
                  type="text"
                  variant="outlined"
                  fullWidth
                  sx={{ maxWidth: "736px" }}
                />
              );
            }}
          </Field>
          <MessageWrapper>
            <MessageInputFormRow
              data-testid="root-keyword-auto-response"
              currentAccount={account}
              errors={errors}
              values={values}
              fieldName="responseBody"
              isDisabled={false}
              hideUserPersonalization={false}
              placeholder="Message"
              maxChars={420}
              setFieldValue={setFieldValue}
              setShortenedLink={setShortenedLink}
              shortenedLink={shortenedLink}
              touched={touched}
              messageFieldOutlineActive={false}
              formPadding="12px 16px 8px 16px"
              segmentCountActive={false}
            />
          </MessageWrapper>
          <AutomaticMessageIndicator />
          {sequencesEnabled && (
            <KeywordFormSequenceSelect
              account={account}
              keyword={values.keyword}
              enrollInSequence={values.enrollInSequence}
              sequenceId={values.sequenceId}
              setSequenceId={setSequenceId}
              setFieldValue={setFieldValue}
              sequences={sequences}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default RootKeywordForm;
