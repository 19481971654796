import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import get from "lodash/get";
import PropTypes from "prop-types";
import withSizes from "react-sizes";
import { useQueryClient } from "@tanstack/react-query";

import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import Fab from "@mui/material/Fab";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { faBullhorn } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { handleResponse } from "@tesseract/core";
import { useSnackbar } from "notistack";
import AddContactsModal from "./AddContactsModal";
import EditGroupNameModal from "./EditGroupNameModal";
import GroupPageContent from "./GroupPageContent";
import { fetchGroup } from "features/Groups/api";
import Tooltip10dlcNotice from "components/Tooltip10dlcNotice";
import getMessagingRestrictedCopy from "utils/getMessagingRestrictedCopy";

import {
  selectCurrentAccount,
  selectCurrentUser,
} from "features/EntryPoint/containers/App/selectors";
import breakpoints from "utils/styles/breakpoints";
import getNestedId from "utils/getNestedId";
import getPaginatedId from "utils/getPaginatedId";
import PageHeader from "components/Page/PageHeader";
import withRecord from "higherOrderComponents/withRecord";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";

import { SequenceEnroller } from "features/Sequences/components/SequenceEnroller";
import { formatSelectedRecords } from "utils/formatSelectedRecords";

function Group({
  createContactCollectionRequest,
  currentAccount,
  currentUser,
  deleteGroupRequest,
  groupId,
  history,
  isSinglePanel,
  location,
  match,
  showSidebar,
  toggleSidebar,
  updateGroupRequest,
}) {
  const queryClient = useQueryClient();
  const [group, setGroup] = useState({});
  const [deleteModalVisibility, setDeleteModalVisibility] = useState(false);
  const [addContactModalVisibility, setContactModalVisibility] =
    useState(false);
  const [editGroupNameModalVisibility, setEditGroupNameModalVisibility] =
    useState(false);
  const [isSendDisabled, setIsSendDisabled] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [showSelectAll] = useState(true);

  const { enqueueSnackbar } = useSnackbar();

  const { messagingRestricted } = currentAccount;
  const visibility = messagingRestricted ? "visible" : "hidden";
  const targeted10dlcNotice = getMessagingRestrictedCopy(
    currentAccount,
    currentUser,
    "Send Campaign",
  );

  const contactCollectionId = getPaginatedId(getNestedId(group, "contacts"), {
    location,
  });

  useEffect(() => {
    // Previously this was not including contacts in the group fetch. However the select all feature requires a list of all the contact IDs in the group. So I've made a boolean that controls the visibility of the select all feature, and I am referencing its inverse here because if select all is not in use, we dont need to fetch the contacts.
    fetchGroup(groupId, { excludeContacts: !showSelectAll })
      .then(handleResponse)
      .then((responseGroup) => {
        return setGroup(responseGroup);
      })
      .catch((error) => {
        return console.error(error);
      });
  }, [groupId, showSelectAll]);

  const toggleDeleteGroupModalVisibility = () => {
    setDeleteModalVisibility(!deleteModalVisibility);
  };

  const toggleContactModalVisibility = () => {
    setContactModalVisibility(!addContactModalVisibility);
  };

  const toggleEditGroupNameModalVisibility = () => {
    setEditGroupNameModalVisibility(!editGroupNameModalVisibility);
  };

  const handleCreateCampaignClick = () => {
    history.push({
      pathname: `/${currentAccount.slug}/campaigns/new`,
      state: { contactCollectionId: getNestedId(group, "contacts") },
    });
  };

  // this query is initiated by ui/app/features/Groups/pages/Group/GroupPageContent.tsx
  async function invalidateGroupQuery() {
    await queryClient.invalidateQueries({
      queryKey: [contactCollectionId],
    });
  }

  const handleAddContacts = (contactIds) => {
    toggleContactModalVisibility();
    createContactCollectionRequest(
      getPaginatedId(getNestedId(group, "contacts"), {
        location,
      }),
      {
        contacts: contactIds,
      },
      {
        successCallback: async () => {
          await invalidateGroupQuery();

          enqueueSnackbar("You successfully added contact(s) to your group.", {
            variant: "info",
          });
        },
        errorCallback: () => {
          enqueueSnackbar("We were unable to add contact(s) to your group.", {
            variant: "error",
          });
        },
      },
    );
  };

  const handleDeleteGroup = () => {
    deleteGroupRequest(
      group.id,
      {},
      {
        successCallback: () => {
          enqueueSnackbar("You successfully deleted the group.", {
            variant: "info",
          });
          history.push({
            pathname: match.url.replace(`/${group.slug}`, ""),
          });
        },
        errorCallback: () => {
          enqueueSnackbar("We were unable to delete the group.", {
            variant: "error",
          });
        },
      },
    );
  };

  const handleEditGroupName = (updatedName) => {
    updateGroupRequest(
      group.id,
      { group: group.slug, name: updatedName },
      {
        successCallback: () => {
          enqueueSnackbar("You successfully updated this group's name.", {
            variant: "info",
          });
        },
        errorCallback: (errors) => {
          const validationErrors = get(
            errors,
            ["validationErrors", "name"],
            undefined,
          );
          enqueueSnackbar(
            `${
              validationErrors
                ? `The name "${updatedName}" ${validationErrors}.`
                : "We were unable to update this group."
            }`,
            { variant: "error" },
          );
        },
      },
    );
  };

  const handleDelete = () => {
    handleDeleteGroup();
    toggleDeleteGroupModalVisibility();
  };

  const handleEdit = (values) => {
    const { name } = values;
    handleEditGroupName(name);
    toggleEditGroupNameModalVisibility();
  };

  const handleAllSelected = (isAllSelected) => {
    if (!showSelectAll) return;

    const members = group?.contacts?.members.map((member) => {
      return member.id;
    });

    setAllSelected(isAllSelected);

    if (isAllSelected) {
      setSelected(members);
      setSelectedRecords(group?.contacts?.members);
    } else {
      setSelected([]);
    }
  };

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <ConfirmationModal
        cancellationHandler={toggleDeleteGroupModalVisibility}
        confirmationHandler={handleDelete}
        isOpen={deleteModalVisibility}
        message="You are about to delete this group. This action cannot be reversed. "
        title="Delete this group?"
      />
      <AddContactsModal
        currentAccount={currentAccount}
        handleAddContacts={handleAddContacts}
        closeModal={toggleContactModalVisibility}
        visible={addContactModalVisibility}
      />
      {/* retrieved async - so wait until in memory */}
      {group?.name ? (
        <EditGroupNameModal
          groupName={group.name}
          handleEdit={handleEdit}
          closeModal={toggleEditGroupNameModalVisibility}
          visible={editGroupNameModalVisibility}
        />
      ) : null}

      <PageHeader
        aria-label={group.name}
        data-test={group.name}
        title={group.name}
        toggleEditModal={toggleEditGroupNameModalVisibility}
        toggleSidebar={toggleSidebar}
        showSidebar={showSidebar}
      >
        {isSinglePanel ? (
          <Box flex="0 0 auto" mr={2}>
            <Tooltip title="Delete Group">
              <IconButton
                aria-label="Delete Group"
                onClick={toggleDeleteGroupModalVisibility}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>

            {Boolean(get(group, ["contacts", "totalItems"])) && (
              <Tooltip title="Send Campaign">
                <IconButton
                  aria-label="Send Campaign"
                  onClick={handleCreateCampaignClick}
                  style={{ marginLeft: "8px" }}
                  size="large"
                >
                  <FontAwesomeIcon
                    icon={faBullhorn}
                    style={{ fontSize: "18px" }}
                  />
                </IconButton>
              </Tooltip>
            )}
            <SequenceEnroller
              allSelected={allSelected}
              buttonType="icon"
              currentAccount={currentAccount}
              contactCollectionId={getPaginatedId(
                getNestedId(group, "contacts"),
                {
                  location,
                },
              )}
              formattedSelectedRecords={formatSelectedRecords(selectedRecords)}
              setSelected={setSelected}
              setSelectedRecords={setSelectedRecords}
            />
          </Box>
        ) : (
          <Box flex="0 0 auto" mr={2}>
            <Button
              aria-label="Delete Group"
              data-testid="delete-group"
              color="primary"
              onClick={toggleDeleteGroupModalVisibility}
              variant="outlined"
            >
              Delete Group
            </Button>

            {Boolean(get(group, ["contacts", "totalItems"])) && (
              <Tooltip10dlcNotice
                text={targeted10dlcNotice}
                visibility={visibility}
              >
                {/* Extra span wrapper required to make disabled button interactive and show tooltip */}
                <span>
                  <Button
                    aria-label="Send Campaign"
                    data-testid="send-campaign"
                    color="primary"
                    onClick={handleCreateCampaignClick}
                    variant="contained"
                    style={{ marginLeft: "8px" }}
                    disabled={isSendDisabled || messagingRestricted}
                  >
                    Send Campaign
                  </Button>
                </span>
              </Tooltip10dlcNotice>
            )}
            <SequenceEnroller
              allSelected={allSelected}
              buttonType="text"
              currentAccount={currentAccount}
              contactCollectionId={getPaginatedId(
                getNestedId(group, "contacts"),
                {
                  location,
                },
              )}
              formattedSelectedRecords={formatSelectedRecords(selectedRecords)}
              setSelected={setSelected}
              setSelectedRecords={setSelectedRecords}
            />
          </Box>
        )}
      </PageHeader>
      {contactCollectionId ? (
        <GroupPageContent
          contactCollectionId={contactCollectionId}
          currentAccount={currentAccount}
          setIsSendDisabled={setIsSendDisabled}
          selected={selected}
          selectedRecords={selectedRecords}
          setSelected={setSelected}
          setSelectedRecords={setSelectedRecords}
          showSelectAll={showSelectAll}
          allSelected={allSelected}
          setAllSelected={handleAllSelected}
        />
      ) : null}
      <Box
        position="absolute"
        right="25px"
        bottom={get(group, ["contacts", "totalItems"], 0) > 0 ? "75px" : "25px"}
      >
        <Fab
          aria-label="Add Group Contacts"
          data-testid="add-group-contacts"
          color="primary"
          onClick={toggleContactModalVisibility}
        >
          <AddIcon
            style={{
              width: 28,
              height: 28,
            }}
          />
        </Fab>
      </Box>
    </Box>
  );
}

Group.propTypes = {
  groupId: PropTypes.string.isRequired,
  createContactCollectionRequest: PropTypes.func.isRequired,
  currentAccount: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
  deleteGroupRequest: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  isSinglePanel: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  showSidebar: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  updateGroupRequest: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  return {
    currentAccount: selectCurrentAccount(state, props),
    currentUser: selectCurrentUser(state, props),
  };
};

const withConnect = connect(() => {
  return mapStateToProps;
});

export default compose(
  withRecord({
    actions: ["delete", "update"],
    container: "features/Groups/pages/Group/group",
    shape: { contacts: {} },
    type: "group",
  }),
  withRecord({
    actions: ["create"],
    container: "features/Groups/pages/Group/contactCollection",
    shape: { members: [{ phones: { members: [] } }] },
    type: "contactCollection",
  }),
  withConnect,
  withSizes(({ width }) => {
    return {
      isSinglePanel: width < breakpoints.medium,
    };
  }),
)(Group);
