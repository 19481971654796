/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction } from "react";
import { fetchKeywords } from "../api";
import { useCurrentAccount, useDebouncedSearch, useLoading } from "hooks";
import { KeywordResponse } from "models/Keyword";
import { ACCOUNT_SETTINGS_KEYS } from "features/AccountSettings/constants/settingsKeys";
import { EFFECTIVE_ROLES } from "features/Accounts/constants";

const useKeywordTable = (
  keywords: KeywordResponse | null,
  setKeywords: Dispatch<SetStateAction<KeywordResponse | null>>,
) => {
  const { startLoading, stopLoading, isLoading } = useLoading();
  const { enqueueSnackbar } = useSnackbar();
  const currentAccount = useCurrentAccount();
  const { debouncedSearchTerm, searchTerm, handleSearch, handleClear } =
    useDebouncedSearch();

  const { effectiveRole, settings } = currentAccount;
  const { locked = false } = settings[ACCOUNT_SETTINGS_KEYS.keywordsLock];
  const userLocked = locked && effectiveRole === EFFECTIVE_ROLES.user;

  const fetchFirstPage = async ({
    active = true,
    items = 10,
    keywordCont = "",
    mine = true,
  } = {}) => {
    try {
      // Fetch keywords.
      startLoading();
      const response = await fetchKeywords(
        currentAccount,
        `q[active]=${active}&q[mine]=${mine}&q[keywordCont]=${keywordCont}&items=${items}
        }`,
      );

      if (response.ok) {
        const keywordsResponse = await response.json();
        setKeywords(keywordsResponse);
      }
    } catch {
      enqueueSnackbar(
        "There was a problem loading keywords. Please try again.",
        {
          variant: "error",
        },
      );
    } finally {
      stopLoading();
    }
  };

  const fetchNextPage = async () => {
    if (!keywords?.page.nextUrl || keywords.page.page === keywords.page.pages) {
      // If we're on the last page, or if there isn't a next page, just bail
      return undefined;
    }

    try {
      startLoading();
      const response = await fetchKeywords(
        undefined,
        undefined,
        keywords?.page.nextUrl,
      );

      const newKeywords = await response.json();

      return setKeywords({
        items: [...keywords.items, ...newKeywords.items],
        page: newKeywords.page,
      });
    } catch (error) {
      console.error(error);
      return enqueueSnackbar("Error loading Keywords.", {
        variant: "error",
      });
    } finally {
      stopLoading();
    }
  };

  return {
    debouncedSearchTerm,
    fetchFirstPage,
    fetchNextPage,
    handleSearch,
    handleClear,
    isLoading,
    searchTerm,
    userLocked,
  };
};

export default useKeywordTable;
