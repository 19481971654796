import { Component, PropsWithChildren } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";
import { ThemeProvider, ThemeOptions } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import { LicenseInfo } from "@mui/x-license-pro";
import { ThemeProvider as StyledComponentsThemeProvider } from "styled-components";
import { createTheme } from "@tesseract/theme";
import type { AppColors } from "@tesseract/theme";
import { selectAppColors } from "features/EntryPoint//containers/App/selectors";
import saga from "features/EntryPoint/containers/App/saga";
import DragListener from "features/EntryPoint/containers/DragListener";
import LogoGradient from "components/LogoGradient";
import TooltipContainer from "features/EntryPoint/containers/TooltipContainer";
import getTheme from "utils/theme";
import injectSaga from "utils/injectSaga";
import { Snackbar } from "components/Snackbar";

type Props = PropsWithChildren<{
  themeOverrides?: ThemeOptions;
}>;

type ComposedProps = {
  appColors: AppColors;
};

class Root extends Component<Props & ComposedProps> {
  static propTypes = {
    appColors: PropTypes.object.isRequired,
    themeOverrides: PropTypes.object,
    children: PropTypes.node.isRequired,
  };

  render() {
    // for MUI X Pro, OK to expose https://mui.com/x/introduction/licensing/#security
    LicenseInfo.setLicenseKey(
      "35289612388bcc8cca1cd2890eb93dceTz0xMDY2NjksRT0xNzY5NDcxOTk5MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI=",
    );

    const { appColors } = this.props;
    const muiTheme = createTheme(appColors, this.props.themeOverrides);
    const appTheme = getTheme({ appColors, muiTheme });
    return (
      <ThemeProvider theme={muiTheme}>
        <StyledComponentsThemeProvider theme={appTheme}>
          <DragListener>
            <div id="textus-ModalContainer" />
            <LogoGradient />
            <TooltipContainer />
            <SnackbarProvider
              Components={{
                info: Snackbar,
                success: Snackbar,
                error: Snackbar,
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              autoHideDuration={5000}
              // @ts-expect-error notistack doesn't have a prop type for data-testid, but it works
              SnackbarProps={{ "data-testid": "Snackbar" }}
            >
              {this.props.children}
            </SnackbarProvider>
          </DragListener>
        </StyledComponentsThemeProvider>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state: unknown) => {
  return {
    appColors: selectAppColors(state),
  };
};

const withConnect = connect(mapStateToProps, {});

const withSaga = injectSaga({ key: "EntryPoint/App", saga });

export const Themed = compose(
  withSaga,
  withConnect,
)(Root) as typeof Component<Props>;
