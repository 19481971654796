import { useState } from "react";
import { Box, Chip, InputBase, Button } from "@mui/material";
import { textUsApiV3 as api } from "services";

export default function NoteCompose({
  conversation,
  contact,
  disabled,
}: {
  conversation: string;
  contact: string;
  disabled?: boolean;
}) {
  const maxNoteLength = 420;
  const conversationId = conversation.split("/").pop();

  const [note, setNote] = useState({
    content: "",
    conversation_id: conversationId,
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (!note.content) {
      return;
    }

    try {
      api.post(`${contact}/notes`, note);
    } catch (error) {
      console.error(error);
      return;
    }

    setNote({
      content: "",
      conversation_id: conversationId,
    });

    e.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box
        sx={(theme) => {
          return {
            bgcolor: theme.palette.yellow[50],
          };
        }}
      >
        <InputBase
          multiline
          rows={2}
          inputProps={{
            "data-testid": "note-compose-field",
            disabled,
            name: "notes",
            placeholder: "Notes are only visible to your team.",
            value: note.content,
            onChange: (e) => {
              if (e.target.value.length <= maxNoteLength) {
                setNote({ ...note, content: e.target.value });
              }
            },
            sx: {
              width: "100%",
              minHeight: "40px",
              border: 0,
              outline: "none",
              "&:focused": {
                border: 0,
                outline: "none",
              },
              "&:hover": {
                border: 0,
                outline: "none",
              },
            },
          }}
          sx={{ width: "100%", padding: "1rem" }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: ".5rem 1rem",
          }}
        >
          <Chip label={`${note.content.length}/${maxNoteLength}`} />
          <Button
            type="submit"
            variant="contained"
            disabled={disabled || note.content.length === 0}
            data-testid="new-message-send-button"
          >
            POST
          </Button>
        </Box>
      </Box>
    </form>
  );
}
