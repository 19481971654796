import { Box, MenuItem } from "@mui/material";
import { Field, FieldProps } from "formik";
import { capitalize } from "lodash";
import { TIME_INCREMENT_OPTIONS } from "../constants";
import { RelativeTimePickerProps } from "../types";
import { Input } from "components/Input";

function RelativeTimePicker({
  errors,
  touched,
  values,
  getValueOptions,
  handleSelect,
}: RelativeTimePickerProps) {
  const { days, hours, minutes } = TIME_INCREMENT_OPTIONS;
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      width="100%"
      marginTop={2}
      marginBottom={2}
    >
      <Box width="30%">
        <Field name="delay.days" type="text">
          {({ field }: FieldProps) => {
            return (
              <Input
                name={field.name}
                fullWidth
                // Type errors are a Formik type issue, but this works
                // @ts-ignore
                helperText={touched.delay?.days && errors.delay?.days}
                inputProps={{ "data-lpignore": true }}
                select
                data-testid="days-time-picker"
                label={capitalize(days)}
                value={values.delay?.days}
                SelectProps={{
                  MenuProps: {
                    PaperProps: { sx: { maxHeight: 172 } },
                  },
                }}
                onChange={handleSelect}
              >
                {getValueOptions(days).map(({ value, label }) => {
                  return (
                    <MenuItem
                      data-testid={`day-${value}`}
                      key={label}
                      value={value}
                    >
                      {label}
                    </MenuItem>
                  );
                })}
              </Input>
            );
          }}
        </Field>
      </Box>
      <Box width="30%">
        <Field name="delay.hours" type="text" padding="12px">
          {({ field }: FieldProps) => {
            return (
              <Input
                name={field.name}
                fullWidth
                // Type errors are a Formik type issue, but this works
                // @ts-ignore
                helperText={touched.delay?.hours && errors.delay?.hours}
                inputProps={{ "data-lpignore": true }}
                select
                data-testid="hours-time-picker"
                label={capitalize(hours)}
                value={values.delay?.hours}
                SelectProps={{
                  MenuProps: {
                    PaperProps: { sx: { maxHeight: 172 } },
                  },
                }}
                onChange={handleSelect}
              >
                {getValueOptions(hours).map(({ value, label }) => {
                  return (
                    <MenuItem
                      data-testid={`hour-${value}`}
                      key={label}
                      value={value}
                    >
                      {label}
                    </MenuItem>
                  );
                })}
              </Input>
            );
          }}
        </Field>
      </Box>
      <Box width="30%">
        <Field name="delay.minutes" type="text">
          {({ field }: FieldProps) => {
            return (
              <Input
                name={field.name}
                fullWidth
                // Type errors are an internal Formik issue, but this works
                // @ts-ignore
                helperText={touched.delay?.minutes && errors.delay?.minutes}
                inputProps={{ "data-lpignore": true }}
                select
                data-testid="minutes-time-picker"
                label={capitalize(minutes)}
                value={values.delay?.minutes}
                SelectProps={{
                  MenuProps: {
                    PaperProps: { sx: { maxHeight: 172 } },
                  },
                }}
                onChange={handleSelect}
              >
                {getValueOptions(minutes).map(({ value, label }) => {
                  return (
                    <MenuItem
                      data-testid={`minutes-${value}`}
                      key={label}
                      value={value}
                    >
                      {label}
                    </MenuItem>
                  );
                })}
              </Input>
            );
          }}
        </Field>
      </Box>
    </Box>
  );
}
export default RelativeTimePicker;
