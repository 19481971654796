export const ACCOUNT_SETTINGS_KEYS = Object.freeze({
  autoReplyLocked: "autoReplyMessageEnabled",
  campaignCutoffTime: "campaignDeliveryCutoffTime",
  keywordsLock: "automationKeywordsAdminLockEnabled",
  introMessages: "introMessages",
  maxCampaignRecipients: "maxCampaignRecipients",
  dailyCampaignRecipients: "dailyCampaignRecipients",
  timeZone: "timeZone",
  // TODO: Keep building this out as needed
});
