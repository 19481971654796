import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import queryString from "query-string";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";

import { AccountSearch } from "./AccountSearch";
import AccountTree from "./AccountTree";
import animation from "components/TopLevelPage/constants/animation";
import H3 from "components/H3";
import TopLevelPageHeader from "components/TopLevelPage/Header";
import TopLevelPageRoot from "components/TopLevelPage/Root";
import TopLevelPageContainer from "components/TopLevelPage/Container";
import TopLevelTableTile from "components/TopLevelPage/TableTile";

const { duration: animationDuration, stagger: animationStagger } = animation;

function AccountManagement({ history, location }) {
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const { q = "" } = queryString.parse(location.search);
    setSearchTerm(q);
  }, [location.search]);

  const accountHierarchyId = queryString.stringifyUrl({
    url: location.pathname,
    query: {
      ancestors: true,
      ...(searchTerm ? { q: searchTerm } : {}),
      ...queryString.parse(location.search, {
        skipNull: true,
        skipEmptyString: true,
      }),
    },
  });

  return (
    <TopLevelPageRoot noScroll>
      <TopLevelPageContainer noPaddingOnMobile>
        <CSSTransition
          key="top-level-page-header"
          timeout={animationDuration}
          classNames="transition"
        >
          <TopLevelPageHeader
            animationDuration={animationDuration}
            style={{ justifyContent: "space-between" }}
          >
            <H3>Accounts</H3>
          </TopLevelPageHeader>
        </CSSTransition>
        <CSSTransition key="tiles" classNames="transition" timeout={0}>
          <TopLevelTableTile
            animationDuration={animationDuration}
            animationStagger={animationStagger}
          >
            <AccountSearch history={history} location={location} />
            <Box
              aria-label="Account Tree"
              height="calc(100% - 55px)"
              width="100%"
            >
              <AccountTree
                accountHierarchyId={accountHierarchyId}
                key={accountHierarchyId}
              />
            </Box>
          </TopLevelTableTile>
        </CSSTransition>
      </TopLevelPageContainer>
    </TopLevelPageRoot>
  );
}

AccountManagement.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default AccountManagement;
