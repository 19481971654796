import MobileFriendlyIcon from "@mui/icons-material/MobileFriendly";
import PhonelinkEraseIcon from "@mui/icons-material/PhonelinkErase";
import SpeakerNotesOffIcon from "@mui/icons-material/SpeakerNotesOff";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import { Box, Tooltip, Typography } from "@mui/material";

interface DeliverabilityStatusProps {
  deliverabilityStatus: string;
  displayText?: boolean;
}

export default function DeliverabilityStatus({
  deliverabilityStatus = "",
  displayText = false,
}: DeliverabilityStatusProps) {
  const getContent = () => {
    switch (deliverabilityStatus) {
      case "mobile":
        return {
          title: "This number may be textable",
          icon: <SmartphoneIcon fontSize="inherit" />,
        };
      case "deliverable":
        return {
          title: "This number is textable",
          icon: <MobileFriendlyIcon fontSize="inherit" />,
        };
      case "opted_out":
        return {
          title: "This number is opted out",
          icon: <SpeakerNotesOffIcon fontSize="inherit" />,
          text: "Opted out",
        };
      case "unknown":
        return {
          title: "This number may be textable",
          icon: <SmartphoneIcon fontSize="inherit" />,
          text: "Unknown",
        };
      case "undeliverable":
        return {
          title: "This number is not textable",
          icon: (
            <PhonelinkEraseIcon
              fontSize="inherit"
              data-testid="this-number-is-not-textable"
            />
          ),
          text: "Invalid recipient",
        };
      default:
        return { title: "", icon: null };
    }
  };

  const { icon, title, text } = getContent();

  if (displayText) {
    return (
      <Tooltip title={title}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          color="text.secondary"
        >
          {icon}
          <Typography variant="caption" pl="0.2rem">
            {text}
          </Typography>
        </Box>
      </Tooltip>
    );
  }

  return icon ? <Tooltip title={title}>{icon}</Tooltip> : null;
}
