import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Formik, Form } from "formik";
import { Box, Typography } from "@mui/material";
import KeyIcon from "@mui/icons-material/Key";
import { DirectoryTree } from "components/DirectoryTree";
import { DialogFooter } from "components/DialogFooter";
import PageHeader from "components/Page/PageHeader";
import PageContent from "components/Page/PageContent";
import {
  KeywordFormComponentProps,
  KeywordFormProps,
} from "features/Keywords/types";
import RootKeywordForm from "./RootKeywordForm";
import SubKeywordForm from "./SubKeywordForm";
import AddKeywordAnswerButton from "./AddKeywordAnswerButton";
import { useCurrentAccount } from "hooks";
import { fetchSequences } from "features/Sequences/api";

export default function KeywordForm({
  isSinglePanel,
  showSidebar,
  toggleSidebar,
}: KeywordFormProps) {
  const [sequences, setSequences] = useState([]);

  const initialValues = {
    keyword: "",
    responseBody: "",
    enrollInSequence: false,
    sequenceId: "",
    keywordAnswers: [],
  };

  const currentAccount = useCurrentAccount();
  const history = useHistory();

  const onCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  const fetchSequenceList = useCallback(async () => {
    const response = await fetchSequences(currentAccount, { activeOnly: true });
    const body = await response.json();

    setSequences(body);
  }, [currentAccount]);

  useEffect(() => {
    fetchSequenceList();
  }, [currentAccount, fetchSequenceList]);

  const isConfirmDisabled = true; // TODO: Implement validation when form is complete

  const icon = <KeyIcon sx={{ fontSize: 20 }} />;

  const onClick = useCallback((keywordAnswers, setFieldValue) => {
    if (keywordAnswers.length >= 20) {
      return;
    }
    const updatedKeywordAnswers = [
      ...keywordAnswers,
      {
        keyword: "",
        responseBody: "",
        enrollInSequence: false,
        sequenceId: "",
      },
    ];
    setFieldValue("keywordAnswers", updatedKeywordAnswers);
  }, []);

  const keywordAnswersComponents = ({
    errors,
    touched,
    values,
    setFieldValue,
  }: KeywordFormComponentProps) => {
    const { keywordAnswers } = values;
    const keywordAnswersComponents = values.keywordAnswers.map(
      (_keywordAnswer, index) => {
        return (
          <SubKeywordForm
            key={`keywordAnswer${index}`}
            index={index}
            errors={errors}
            touched={touched}
            values={values}
            setFieldValue={setFieldValue}
            sequences={sequences}
          />
        );
      },
    );

    if (keywordAnswers.length >= 20) {
      return keywordAnswersComponents;
    }

    return keywordAnswersComponents.concat([
      addKeywordAnswerButton(values.keywordAnswers, setFieldValue),
    ]);
  };

  const addKeywordAnswerButton = (keywordAnswers, setFieldValue) => {
    return (
      <AddKeywordAnswerButton
        onClick={() => onClick(keywordAnswers, setFieldValue)}
      />
    );
  };

  return (
    <PageContent isSinglePanel={isSinglePanel} showSidebar={showSidebar}>
      <PageHeader title="Create Keyword" toggleSidebar={toggleSidebar} />
      <Box>
        <Formik initialValues={initialValues} onSubmit={onCancel}>
          {({
            errors,
            touched,
            values,
            isSubmitting,
            isValid,
            setFieldValue,
          }) => {
            return (
              <Form
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    padding: "40px 0px 0px 96px",
                    height: "fit-content",
                    maxHeight: "calc(100vh - 150px)",
                    overflow: "scroll",
                    "&::-webkit-scrollbar": { display: "none" },
                  }}
                >
                  <Typography component="h3" variant="h3" marginBottom="40px">
                    New Automation
                  </Typography>
                  <Box>
                    <DirectoryTree
                      icon={icon}
                      rootComponent={
                        <RootKeywordForm
                          errors={errors}
                          touched={touched}
                          values={values}
                          setFieldValue={setFieldValue}
                          sequences={sequences}
                        />
                      }
                      childComponents={keywordAnswersComponents({
                        errors,
                        touched,
                        values,
                        setFieldValue,
                        sequences,
                      })}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "fill-available",
                    position: "fixed",
                    bottom: "0",
                    backgroundColor: (theme) => theme.palette.background.paper,
                  }}
                >
                  <DialogFooter
                    onCancel={onCancel}
                    confirmText="Create"
                    isConfirmDisabled={
                      isSubmitting || isValid || isConfirmDisabled
                    }
                  />
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </PageContent>
  );
}
