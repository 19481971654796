import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid2 as Grid,
  Link as BasicLink,
  Stack,
  Typography,
  Tooltip as MuiTooltip,
} from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Save from "@mui/icons-material/Save";
import { Formik, Form as FormikForm } from "formik";
import { useMemo, useCallback, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import { sanitize } from "../../../utils/sanitize";
import { Form, FormRef, FormValues } from "./components/Form";
import type { Props } from "./types";
import { s } from "./utils";
import { createBlankRecords } from "utils/createBlankRecords";
import type { Account } from "features/Accounts";
import { Dialog, useDialog } from "components/Dialog";

export function Create({
  dispatcher,
  isSubmitting,
  currentAccount,
  serverErrors,
}: Props) {
  const history = useHistory();
  const snackbar = useSnackbar();
  const formRef = useRef<FormRef>(null);

  const { initialValues } = useMemo<{
    initialValues: FormValues;
  }>(() => {
    return {
      initialValues: { accounts: createBlankRecords(6) },
    };
  }, []);

  const { ref, open: confirm } = useDialog<number, boolean>();

  const { ref: confirmNavigationRef, open: confirmNavigation } = useDialog<
    undefined,
    boolean
  >();

  const onSubmit = useCallback(
    ({ accounts }: FormValues) => {
      const sanitized = sanitize<Record<string, Account>>(accounts);

      const accountMissingUsers = Object.entries(sanitized).find(
        ([, _account]) => {
          return Boolean(!_account?.users);
        },
      );

      if (accountMissingUsers) {
        snackbar?.enqueueSnackbar("Add at least 1 user to an account.", {
          variant: "error",
        });
        formRef.current?.openUsersDrawer(accountMissingUsers);
      } else {
        // open conformation dialog
        confirm(Object.keys(sanitized).length, (result) => {
          if (result) {
            dispatcher.dispatch("createMany", currentAccount, sanitized);
          }
        })();
      }
    },
    [currentAccount, confirm, dispatcher, snackbar],
  );

  return (
    <>
      <Grid
        p={2}
        pr={0}
        container
        gridTemplateColumns="auto 1fr"
        justifyContent="space-between"
      >
        <Grid>
          <Typography
            variant="h6"
            component="h1"
            sx={{ letterSpacing: "0.15px", fontWeight: 500 }}
          >
            Add Accounts
          </Typography>
          <Typography variant="body1" component="p">
            Create up to 100 accounts at a time. Submit a request to the{" "}
            <Link
              to={{ pathname: "https://help.textus.com/s/contactsupport" }}
              target="_blank"
            >
              TextUs Support Team
            </Link>{" "}
            if you need more than 100 accounts or if you need
            toll-free/international (outside the US or Canada) messaging
            accounts.
          </Typography>
          <Typography sx={{ pt: "25px" }}>
            Our team reviews account submissions for accuracy and will
            facilitate the provisioning of phone numbers. Accounts submitted
            with valid information by 2 PM MT on business days (Monday-Friday)
            will be created within 1-3 business days.
          </Typography>
        </Grid>
        <MuiTooltip sx={{ height: 23, display: "none" }} title="Coming soon!">
          <Grid sx={{ mt: 2 }}>
            <Button disabled variant="contained" component="button">
              CSV Import
            </Button>
          </Grid>
        </MuiTooltip>
      </Grid>
      <Formik onSubmit={onSubmit} initialValues={initialValues}>
        {({ values, dirty, ...props }) => {
          return (
            <FormikForm>
              <Form
                currentAccount={currentAccount}
                ref={formRef}
                dirty={dirty}
                values={values}
                serverErrors={serverErrors}
                {...props}
                isSubmitting={isSubmitting}
              />
              <Grid container justifyContent="flex-end" gap={3}>
                {dirty && !isEmpty(sanitize(values.accounts)) ? (
                  <Button
                    component="button"
                    size="large"
                    onClick={confirmNavigation(undefined, (result) => {
                      if (result) {
                        history.push("../accounts");
                      }
                    })}
                  >
                    Cancel
                  </Button>
                ) : (
                  <Button component={Link} to="../accounts" size="large">
                    Cancel
                  </Button>
                )}
                {isSubmitting ? (
                  <LoadingButton
                    color="primary"
                    loading
                    loadingPosition="start"
                    size="large"
                    startIcon={<Save />}
                    variant="contained"
                  >
                    Add Accounts
                  </LoadingButton>
                ) : (
                  <Button
                    color="primary"
                    disabled={!dirty || isEmpty(sanitize(values.accounts))}
                    data-testid="accounts-added"
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Add Accounts
                  </Button>
                )}
              </Grid>
            </FormikForm>
          );
        }}
      </Formik>
      <Dialog<number, boolean> ref={ref}>
        {({ close, parameters: count }) => {
          return (
            <Stack
              sx={{
                maxWidth: "620px",
              }}
            >
              <DialogTitle display="flex" gap={1} alignItems="center">
                <CheckCircleOutlineIcon fontSize="small" />
                <Grid>Confirm Account{s(count ?? 0)} Added</Grid>
              </DialogTitle>
              <DialogContent>
                <Typography variant="body1" mb={2}>
                  Please confirm you are adding {count} new messaging accounts
                  to your organization.
                </Typography>
                <Typography variant="body1" mb={2}>
                  <b>
                    By clicking confirm for these accounts, you acknowledge that
                    your organization may incur additional charges if the total
                    number of accounts exceeds the original order form.
                  </b>
                </Typography>
                <Typography variant="body1">
                  If you have any questions, please contact your Account Manager
                  or reach out to{" "}
                  <BasicLink
                    color="textPrimary"
                    underline="none"
                    href="mailto:am@textus.com"
                  >
                    am@textus.com
                  </BasicLink>
                  .
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={close(false)}>Cancel</Button>
                <Button variant="contained" onClick={close(true)} autoFocus>
                  Confirm
                </Button>
              </DialogActions>
            </Stack>
          );
        }}
      </Dialog>
      <Dialog<undefined, boolean>
        ref={confirmNavigationRef}
        defaultCloseValue={false}
      >
        {({ close }) => {
          return (
            <>
              <DialogTitle>Warning</DialogTitle>
              <DialogContent>
                <DialogContentText>Unsaved changes</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={close(false)}>Cancel</Button>
                <Button onClick={close(true)} autoFocus>
                  Discard
                </Button>
              </DialogActions>
            </>
          );
        }}
      </Dialog>
    </>
  );
}
