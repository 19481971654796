import { Account } from "@tesseract/core";
import { textUsApiV4 } from "services";

/**
 * @deprecated
 * Use ui/app/features/Keywords/api/archiveKeywords.tsx
 */
export const deleteAutomationKeyword = (
  currentAccount: Account.Raw,
  id: string,
): Promise<Response> => {
  return textUsApiV4.destroy(
    `/${currentAccount.slug}/automation_keywords/${id}`,
  );
};
