import { IconButton, Tooltip } from "@mui/material";
import { Account } from "@tesseract/core";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { LockIcon, LockOpenIcon } from "icons";
import { useCurrentAccount, useCurrentUser } from "hooks";
import { patchSettings } from "features/AccountSettings/api";
import { isOrgAdmin } from "utils/isOrgAdmin";
import { updateRecords } from "features/EntryPoint/containers/App/actions";
import { EFFECTIVE_ROLES } from "features/Accounts/constants";

interface AdminLockProps {
  settingKey: keyof Account.Settings;
}

function AdminLock({ settingKey }: AdminLockProps) {
  /* HOOKS */
  const currentAccount = useCurrentAccount();
  const user = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  /* VARIABLES */
  const { admin, support } = EFFECTIVE_ROLES;

  const {
    name: currentAccountName,
    id: currentAccountId,
    effectiveRole,
  } = currentAccount;

  const isAdmin =
    isOrgAdmin(user) ||
    ([admin, support] as (keyof typeof EFFECTIVE_ROLES)[]).includes(
      effectiveRole,
    );

  const setting = currentAccount.settings[settingKey];

  const {
    memberships: { members: allAccounts },
  } = user;

  const childAccounts = allAccounts
    .filter(({ account }) => {
      return (
        account?.organizationAccount === currentAccountId &&
        account?.id !== currentAccountId // Exclude the current account
      );
    })
    .map(({ account }) => {
      return account;
    });

  // Type guarding against Account.Settings objects that are pure strings
  // and the "tcrCampaigns" object, which does not include a locked property.
  if (!setting || typeof setting === "string" || "useCase" in setting) {
    return null;
  }

  /* FUNCTIONS */
  const updateReduxAccounts = (updatedAccount: Account.Raw) => {
    // Handles updating child accounts if the setting is being updated on an organization account
    const updatedChildAccounts = childAccounts.map((childAccount) => {
      return {
        ...childAccount,
        settings: {
          ...childAccount.settings,
          [settingKey]: {
            ...(updatedAccount.settings[settingKey] as object),
            setAtAccount: childAccount.id,
          },
        },
      };
    });

    return Object.assign(
      { [currentAccountId]: updatedAccount },
      ...updatedChildAccounts.map((account) => {
        return { [account.id]: account };
      }),
    );
  };

  const handleSubmit = async () => {
    try {
      const response = await patchSettings(currentAccount, {
        [settingKey]: {
          locked: !setting?.locked,
        },
      });

      if (response.ok) {
        const updatedAccount = await response.json();

        const normalizedRecord = {
          account: updateReduxAccounts(updatedAccount),
        };

        dispatch(updateRecords(normalizedRecord)); // Updates account data in Redux store
      }
    } catch {
      enqueueSnackbar("Something went wrong. Please try again.", {
        variant: "error",
      });
    }
  };

  return (
    <>
      {isAdmin ? (
        <Tooltip
          title={
            setting?.locked
              ? `Locked for ${currentAccountName} ${childAccounts.length > 0 ? `and ${childAccounts.length} accounts` : ""}`
              : `Prevent modification for ${currentAccountName} ${childAccounts.length > 0 ? `and ${childAccounts.length} accounts` : ""}`
          }
        >
          <IconButton
            onClick={() => {
              handleSubmit().catch((error) => {
                console.error(error);
              });
            }}
          >
            {setting?.locked ? <LockIcon /> : <LockOpenIcon />}
          </IconButton>
        </Tooltip>
      ) : null}
      {!isAdmin && setting?.locked ? (
        <Tooltip title={`Must be an admin on ${currentAccountName} to modify`}>
          {/* Span needed for tooltip to display on disabled IconButton  */}
          <span>
            <IconButton disabled>
              <LockIcon />
            </IconButton>
          </span>
        </Tooltip>
      ) : null}
    </>
  );
}

export { AdminLock };
