import { useEffect, useState } from "react";
import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Typography,
  Box,
  Tabs,
  Tab,
  Theme,
  Tooltip,
} from "@mui/material";
import KeyIcon from "@mui/icons-material/Key";
import SubKeywordCard from "./SubKeywordCard";
import { fetchAutomationKeyword } from "features/Automation/api/fetchAutomationKeyword";

import PageHeader from "components/Page/PageHeader";
import PageContent from "components/Page/PageContent";
import {
  KeywordProps,
  KeywordObject,
  SubKeyword,
} from "features/Keywords/types";
import { DirectoryTree } from "components/DirectoryTree";
import { useTabs, useCurrentAccount } from "hooks";
import { InfoOutlineIcon } from "icons";
import { EFFECTIVE_ROLES } from "features/Accounts/constants";
import { ACCOUNT_SETTINGS_KEYS } from "features/AccountSettings/constants/settingsKeys";

function Keyword({
  currentAccount,
  keywordId,
  isSinglePanel,
  showSidebar,
  toggleSidebar,
}: KeywordProps) {
  const [keyword, setKeyword] = useState<KeywordObject | null>(null);

  /* EFFECTS */
  useEffect(() => {
    const handleFetchAutomationKeywordById = async () => {
      const response = await fetchAutomationKeyword(currentAccount, keywordId);
      const json = await response.json();

      setKeyword(json);
    };
    handleFetchAutomationKeywordById().catch((error) => {
      console.error(error);
    });
  }, [keywordId, currentAccount]);

  /* HOOKS */
  const { effectiveRole, settings } = useCurrentAccount();

  const {
    selectedTab: selectedKeywordTab,
    handleTabChange: handleKeywordTabChange,
  } = useTabs();

  /* FUNCTIONS */
  const getTotalAnswered = (subKeywords: SubKeyword[]) => {
    return subKeywords?.reduce((acc, subKeyword) => {
      return acc + subKeyword.answeredCount;
    }, 0);
  };

  const hasLink = (messageBody: string) => {
    const regex =
      /(\bhttps?|ftp):\/\/[^\s/$.?#].[^\s]*\b|^(www\.)[^\s/$.?#].[^\s]*$/;
    return regex.test(messageBody);
  };

  /* VARIABLES */
  const settingKey = ACCOUNT_SETTINGS_KEYS.keywordsLock;
  const { locked = false } = settings[settingKey];
  const userLocked = locked && effectiveRole === EFFECTIVE_ROLES.user;
  const subKeywords = keyword?.subKeywords || [];
  const messageHasLink = hasLink(keyword?.responseBody || "");

  const totalAnsweredCount = keyword?.subKeywords
    ? getTotalAnswered(keyword.subKeywords)
    : 0;

  const percentClicked =
    keyword && keyword.timesReceived !== 0
      ? (keyword.clickedLinkCount / keyword.timesReceived) * 100
      : 0;

  /* COMPONENTS AND DISPLAY DATA */
  const keywordCardItems =
    subKeywords.length > 0
      ? [
          {
            title: "Keyword views",
            stat: keyword?.timesReceived,
            tooltip: `The number of subscribers who responded with ${keyword?.keyword}.`,
          },
          {
            title: "Answered",
            stat: totalAnsweredCount,
            tooltip:
              "The total number of subscribers who responded with a keyword answer.",
          },
          {
            title: "Opted out",
            stat: keyword?.optedOutCount,
            tooltip:
              "The total number of subscribers who chose to opt-out after receiving an auto-response.",
          },
        ]
      : [
          {
            title: "Keyword views",
            stat: keyword?.timesReceived,
            tooltip: `The number of subscribers who responded with ${keyword?.keyword}.`,
          },
          {
            title: "Opted out",
            stat: keyword?.optedOutCount,
            tooltip:
              "The total number of subscribers who chose to opt-out after receiving an auto-response.",
          },
        ];

  const childComponentArray = subKeywords?.map((subKeyword) => {
    return (
      <SubKeywordCard
        key={subKeyword.keyword}
        hasLink={hasLink}
        subKeyword={subKeyword}
      />
    );
  });

  const icon = <KeyIcon sx={{ fontSize: 20 }} />;

  return (
    <PageContent isSinglePanel={isSinglePanel} showSidebar={showSidebar}>
      <PageHeader
        title={
          <Breadcrumbs>
            <Typography>Keywords</Typography>
            <Typography color="text.hyperlink">{keyword?.keyword}</Typography>
          </Breadcrumbs>
        }
        toggleSidebar={toggleSidebar}
      />
      <Tabs
        value={selectedKeywordTab}
        onChange={handleKeywordTabChange}
        sx={(theme: Theme) => {
          return {
            borderBottom: `1px solid ${theme.palette.divider}`,
            paddingLeft: "16px",
            display: "flex",
            alignItems: "center",
          };
        }}
      >
        <Tab label="Overview" />
        <Tab label="Subscribers" />
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ width: "100%", paddingRight: "16px" }}
        >
          {keyword?.deletedAt ? (
            <Tooltip title={userLocked ? "Disabled by admin" : ""}>
              <span>
                <Button
                  variant="outlined"
                  disabled={userLocked}
                  sx={{
                    height: "36px",
                    padding: "6px 16px",
                    marginRight: "8px",
                  }}
                >
                  Reactivate
                </Button>
              </span>
            </Tooltip>
          ) : (
            <>
              <Tooltip title={userLocked ? "Disabled by admin" : ""}>
                <span>
                  <Button
                    variant="outlined"
                    disabled={userLocked}
                    sx={{
                      height: "36px",
                      padding: "6px 16px",
                      marginRight: "8px",
                    }}
                  >
                    Edit
                  </Button>
                </span>
              </Tooltip>
              <Tooltip title={userLocked ? "Disabled by admin" : ""}>
                <span>
                  <Button
                    variant="outlined"
                    disabled={userLocked}
                    sx={(theme) => {
                      return {
                        height: "36px",
                        padding: "6px 16px",
                        color: theme.palette.error.main,
                        borderColor: theme.palette.error.main,
                      };
                    }}
                  >
                    Archive
                  </Button>{" "}
                </span>
              </Tooltip>
            </>
          )}
        </Box>
      </Tabs>
      {selectedKeywordTab === 0 ? (
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "flex-start",
            paddingTop: "16px",
          }}
        >
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            padding="16px"
            paddingTop="0px"
            paddingBottom="50px"
            flexDirection="row"
            width="100%"
          >
            {keywordCardItems.map((keywordCardItem) => {
              return (
                <Card key="1" sx={{ margin: "4px" }}>
                  <CardContent
                    sx={{
                      padding: "8px 16px",
                      "&:last-child": {
                        paddingBottom: "8px",
                      },
                    }}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography
                        variant="overline"
                        color="textSecondary"
                        paddingRight="4px"
                        paddingTop="3px"
                      >
                        {keywordCardItem.title}
                      </Typography>
                      <Tooltip
                        title={
                          <Typography
                            sx={{
                              variant: "label",
                              fontSize: "10px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {keywordCardItem.tooltip}
                          </Typography>
                        }
                        slotProps={{
                          tooltip: {
                            sx: {
                              maxWidth: "none",
                            },
                          },
                          popper: {
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [0, -8],
                                },
                              },
                            ],
                          },
                        }}
                      >
                        <span>
                          <InfoOutlineIcon
                            sx={(theme: Theme) => {
                              return {
                                color: theme.palette.text.secondary,
                                fontSize: "20px",
                                stroke: theme.palette.text.secondary,
                                strokeWidth: 10,
                              };
                            }}
                          />
                        </span>
                      </Tooltip>
                    </Box>
                    <Typography
                      display="flex"
                      justifyContent="center"
                      variant="dataMedium"
                      align="center"
                      color="primary"
                      noWrap
                    >
                      {keywordCardItem.stat}
                    </Typography>
                  </CardContent>
                </Card>
              );
            })}
          </Box>
          <DirectoryTree
            icon={icon}
            iconPadding="16px"
            rootComponent={
              <Box
                sx={{
                  alignItems: "flex-start",
                  border: "1px solid #0000001F",
                  borderRadius: "5px",
                  display: "flex",
                  flexDirection: "column",
                  height: "126px",
                  maxWidth: "776px",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    borderBottom: "1px solid #0000001F",
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      display: "flex",
                      height: "56px",
                      padding: "16px",
                      width: "100%",
                    }}
                  >
                    Keyword: {keyword?.keyword}
                  </Typography>
                  <Typography
                    sx={(theme) => {
                      return {
                        alignItems: "flex-end",
                        color: theme.palette.text.secondary,
                        display: "flex",
                        fontSize: "13px",
                        padding: "16px",
                        whiteSpace: "nowrap",
                      };
                    }}
                  >
                    {keyword?.timesReceived} viewed
                  </Typography>
                </Box>
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    minWidth: "568px",
                    padding: "4px 16px",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      height: "52px",
                      maxWidth: "620px",
                      overflow: "hidden",
                      width: "100%",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                        display: "-webkit-box",
                        fontSize: "16px",
                        lineHeight: "1.5",
                        overflow: "hidden",
                        width: "100%",
                        color: "text.secondary",
                      }}
                    >
                      {keyword?.responseBody}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      flex: "1 1 auto",
                      justifyContent: "flex-end",
                      paddingLeft: "24px",
                    }}
                  >
                    {messageHasLink ? (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "16px",
                        }}
                      >
                        <Box textAlign="center">
                          <Typography
                            sx={(theme) => {
                              return {
                                color: theme.palette.primary.main,
                                fontSize: "16px",
                                fontWeight: 700,
                              };
                            }}
                          >
                            {percentClicked}%
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            sx={(theme) => {
                              return {
                                alignSelf: "stretch",
                                color: theme.palette.text.secondary,
                                whiteSpace: "nowrap",
                                fontWeight: 500,
                              };
                            }}
                          >
                            Clicked links
                          </Typography>
                        </Box>
                      </Box>
                    ) : null}
                  </Box>
                </Box>
              </Box>
            }
            childComponents={childComponentArray}
          />
        </Box>
      ) : (
        <Box padding="20px"> Placeholder subscriber content </Box>
      )}
    </PageContent>
  );
}

export default Keyword;
