import { fromJS, OrderedSet } from "immutable";
import { LOCATION_CHANGE } from "connected-react-router";
import get from "lodash/get";

import { lightTheme, darkTheme } from "@tesseract/theme";
import {
  UPDATE_APP_COLORS,
  UPDATE_CURRENT_ACCOUNT,
  UPDATE_EXTRACTED_PHONES,
  UPDATE_RECORDS,
  UPDATE_USER_CAN_HOVER,
  SET_LAST_ACTION,
  CLEAR_LAST_ACTION,
} from "./constants";
import { actionTypes as bootstrapActionTypes } from "features/EntryPoint/containers/Bootstrap/state";
import convertInboxSlugs from "utils/convertInboxSlugs";
import globalNavigationRoutes from "constants/globalNavigationRoutes";
import localStorageHelper from "utils/localStorageHelper";
import updateRecords from "utils/updateRecords";
import getPathnameArray from "utils/getPathnameArray";

const getAppColors = () => {
  let existingTheme = localStorageHelper.getItem("colorTheme", {
    fallback: lightTheme,
  });

  if (typeof existingTheme === "string") {
    if (existingTheme === "dark") {
      existingTheme = darkTheme;
      localStorageHelper.setItem({ item: "colorTheme", value: existingTheme });
    } else {
      existingTheme = lightTheme;
      localStorage.removeItem("colorTheme");
    }
  }

  return existingTheme;
};

const initialState = fromJS({
  appColors: getAppColors(),
  currentAccount: "",
  currentUser: "",
  extractedPhones: OrderedSet([]),
  records: {},
  userCanHover: false,
});

const getUpdatedInboxAttributes = ({ pathname, search }) => {
  const [
    ,
    accountSlug,
    ,
    activeConversationFilterSlug,
    activeConversationSlug,
  ] = getPathnameArray(pathname);
  if (activeConversationFilterSlug) {
    return {
      ...convertInboxSlugs({
        accountSlug,
        activeConversationFilterSlug,
        activeConversationSlug,
        queryParams: search,
      }),
      modifiedInbox: true,
    };
  }
  return {};
};

const handleLocationChange = (state, action) => {
  const [, accountSlug, feature] = getPathnameArray(
    action.payload.location.pathname,
  );
  if (feature !== "inbox") return state;
  if (state.getIn(["records", `/${accountSlug}/inbox`])) {
    return state.mergeIn(["records"], {
      [`/${accountSlug}/inbox`]: state
        .getIn(["records", `/${accountSlug}/inbox`])
        .merge(getUpdatedInboxAttributes(action.payload.location)),
    });
  }
  return state;
};

const createOptimisticMessage = ({ params, currentUserId }) => {
  return {
    id: `/messages/new`,
    "@type": "Message",
    body: params.body,
    attachments: {
      members: params.attachments,
    },
    savedReply: params.saved_reply,
    conversation: params.conversationId,
    deliveryState: "requested",
    direction: "out",
    formattedBody: `<div>${params.body.replaceAll("\n", "<br />")}</div>`,
    sender: currentUserId,
    timelinePosition: new Date(),
  };
};

const updatedConversationProperties = ({ state, newMessage }) => {
  const conversation = state.get("records").get(newMessage.conversation).toJS();
  const participants = state
    .get("records")
    .get(conversation.participants)
    .toJS();
  const timeline = state.get("records").get(conversation.timeline).toJS();
  return {
    [conversation.id]: {
      ...conversation,
      latestPreviewableItem: { id: newMessage.id, schema: "Message" },
    },
    [timeline.id]: {
      ...timeline,
      members: [
        { id: newMessage.id, schema: "Message" },
        ...timeline.members.filter(({ id }) => {
          return id !== "/messages/new";
        }),
      ],
    },
    [participants.id]: {
      ...participants,
      members: [
        newMessage.sender,
        ...participants.members.filter((m) => {
          return m !== newMessage.sender;
        }),
      ],
    },
  };
};

// eslint-disable-next-line default-param-last
const appReducer = (state = initialState, action) => {
  switch (action.type) {
    /* eslint-disable no-case-declarations */
    case LOCATION_CHANGE:
      return handleLocationChange(state, action);
    case UPDATE_APP_COLORS:
      return state.set("appColors", fromJS(action.appColors));
    case "app/NewMessage/CREATE_MESSAGE_REQUEST":
      if (action.params.conversationId) {
        const currentUserId = state.get("currentUser");
        const newMessage = createOptimisticMessage({
          params: action.params,
          currentUserId,
        });
        return state.mergeIn(["records"], {
          ...updatedConversationProperties({
            state,
            newMessage,
          }),
          "/messages/new": newMessage,
        });
      }
      return state;
    case "app/NewMessage/CREATE_MESSAGE_FAILURE":
      if (state.getIn(["records", "/messages/new"])) {
        return state.mergeIn(["records"], {
          "/messages/new": {
            ...state.getIn(["records", "/messages/new"]).toJS(),
            errorCreating: action.response,
          },
        });
      }
      return state;
    case "app/NewMessage/CREATE_MESSAGE_SUCCESS":
      if (state.getIn(["records", "/messages/new"])) {
        return state.deleteIn(["records", "/messages/new"]).mergeIn(
          ["records"],
          updatedConversationProperties({
            state,
            newMessage: action.response,
          }),
        );
      }
      return state;
    case bootstrapActionTypes.FETCH_BOOTSTRAP_SUCCESS:
      const [, accountSlug] = getPathnameArray(document.location.pathname);
      const accountsInNavbar = get(
        action,
        ["response", "accountNavbar", "members"],
        [],
      ).map((accountNavbarItem) => {
        return accountNavbarItem.account;
      });
      const defaultAccountId = `/accounts/${action.response.currentUser.settings?.defaultMessagingAccount}`;
      const defaultAccount =
        accountsInNavbar.find((account) => {
          return account === defaultAccountId;
        }) || accountsInNavbar[0];
      const currentAccount =
        accountSlug && !globalNavigationRoutes.includes(accountSlug)
          ? `/accounts/${accountSlug}`
          : defaultAccount;
      return state
        .set("currentUser", action.response.currentUser.id)
        .set("currentAccount", currentAccount);
    case UPDATE_CURRENT_ACCOUNT:
      return state.set("currentAccount", action.currentAccountId);
    case UPDATE_RECORDS:
      return updateRecords(state, action);
    case UPDATE_EXTRACTED_PHONES:
      return state.set("extractedPhones", OrderedSet(action.payload));
    case UPDATE_USER_CAN_HOVER:
      return state.set("userCanHover", action.payload);
    case SET_LAST_ACTION:
      return state.set("lastAction", action.payload);
    case CLEAR_LAST_ACTION:
      return state.delete("lastAction");
    default:
      return state;
  }
};

export default appReducer;
