import { Account } from "@tesseract/core";
import { textUsApiV4 } from "services";

export const fetchKeywords = (
  currentAccount?: Account.Raw,
  queryString?: string,
  nextPage?: string,
): Promise<Response> => {
  return nextPage
    ? textUsApiV4.get(nextPage.replace("/api/v4", ""))
    : textUsApiV4.get(
        `/${currentAccount?.slug}/automation_keywords?${queryString ?? ""}`,
      );
};
