import { useState, useEffect } from "react";
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { PageHeaderProps } from "./types";
import MenuIcon from "components/MenuIcon";
import { EditIcon, LogoIcon, MarkunreadIcon, SearchIcon } from "icons";
import { SearchBox } from "components/SearchBox";
import { AdminLock } from "components/AdminLock";

export default function PageHeader({
  children,
  className,
  displaySearch = false,
  displayUnanswered = false,
  displayUnread = false,
  searchPlaceholder = "Search",
  settingKey,
  showSearchBox = false,
  showSecondaryNavbar = true,
  title,
  toggleEditModal,
  toggleSidebar,
  toggleSearch,
  toggleUnanswered,
  toggleUnread,
}: PageHeaderProps) {
  // ==== HOOKS ==== //
  const [isOpen, setIsOpen] = useState(true);

  const location = useLocation();

  const queryParams = queryString.parse(location.search, {
    parseBooleans: true,
  });

  useEffect(() => {
    setIsOpen(showSecondaryNavbar);
  }, [showSecondaryNavbar]);

  // ==== RENDER ==== //
  const renderSearch = () => {
    // Ensure we want to display the search and that we provide a toggleSearch function.
    if (!displaySearch || !toggleSearch) {
      return null;
    }

    if (showSearchBox) {
      // This search box does not do searching.
      // Clicking it will send user to `/search` page.
      return (
        <Box width="18rem">
          <SearchBox
            fullWidth
            margin="none"
            value=""
            onClick={toggleSearch}
            onClear={() => {}}
            onChange={() => {}}
            placeholder={searchPlaceholder}
            size="small"
          />
        </Box>
      );
    }

    return (
      <Tooltip title={searchPlaceholder}>
        <IconButton
          aria-label="search"
          data-testid="search"
          onClick={toggleSearch}
          data-product-tour="SearchButton"
          size="large"
        >
          <SearchIcon fontSize="medium" />
        </IconButton>
      </Tooltip>
    );
  };

  const renderUnanswered = () => {
    // Ensure we want to display the unanswered button and that we provide a toggleUnanswered function.
    if (!displayUnanswered || !toggleUnanswered) {
      return null;
    }

    return (
      <Tooltip title="Unanswered">
        <IconButton
          color={queryParams.unanswered ? "secondary" : "default"}
          aria-label="unanswered"
          data-testid="unanswered"
          key="unanswered"
          onClick={toggleUnanswered}
          data-product-tour="UnansweredFilter"
          size="medium"
        >
          <LogoIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    );
  };

  const renderUnread = () => {
    // Ensure we want to display the unread icon and that we provide a toggleUnread function.
    if (!displayUnread || !toggleUnread) {
      return null;
    }

    return (
      <Tooltip title="Unread">
        <IconButton
          color={queryParams.unread ? "secondary" : "default"}
          aria-label="unread"
          data-testid="unread"
          key="unread"
          onClick={toggleUnread}
          data-product-tour="UnreadFilter"
          size="medium"
        >
          <MarkunreadIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    );
  };

  const renderEdit = () => {
    if (!toggleEditModal) {
      return null;
    }

    return (
      <IconButton
        aria-label="edit modal"
        onClick={toggleEditModal}
        style={{ marginLeft: "10px" }}
        size="large"
      >
        <EditIcon fontSize="small" />
      </IconButton>
    );
  };

  return (
    <Box className={className}>
      <AppBar
        position="static"
        sx={{
          boxShadow: "none",
        }}
      >
        <Toolbar
          sx={{
            backgroundColor: "background.paper",
            borderBottom: "1px solid",
            borderBottomColor: "action.selected",
            color: "text.primary",
            "&.MuiToolbar-root": {
              minHeight: "55px",
              padding: "0 8px",
            },
          }}
        >
          <IconButton
            color="primary"
            onClick={() => {
              toggleSidebar();
              setIsOpen(!isOpen);
            }}
            sx={{
              fontSize: "26px",
              "& .MuiBox-root": {
                alignItems: "center",
              },
            }}
            aria-label="toggle-menu"
            size="large"
          >
            <MenuIcon isOpen={isOpen} />
          </IconButton>

          <Typography
            data-testid="title"
            variant="h6"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            color="text.primary"
            sx={{ flexGrow: 1 }}
          >
            {title}
          </Typography>

          <Box>
            {renderSearch()}

            {/* TODO: Filter button */}
            {/* TODO: Refresh button */}

            {renderUnanswered()}
            {renderUnread()}
            {renderEdit()}
            {settingKey ? <AdminLock settingKey={settingKey} /> : null}
          </Box>

          {children}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
