import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  FormControlLabel,
  Switch,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useState } from "react";
import { AuthorizeSalesforceButton } from "../AuthorizeSalesforceButton";
import { AuthorizeJobAdderButton } from "../AuthorizeJobAdderButton";
import { AuthorizeHubspotButton } from "../AuthorizeHubspotButton";
import { OauthIntegrationDialogProps } from "./types";
import { DialogHeader } from "components/DialogHeader";

export function OauthIntegrationDialog({
  currentAccount,
  integration,
  open,
  setOauthDialogOpen,
  setSelectedIntegration,
}: OauthIntegrationDialogProps) {
  const [additionalSettings, setAdditionalSettings] = useState<any>({});

  const { enqueueSnackbar } = useSnackbar();

  const handleCloseModal = () => {
    enqueueSnackbar("Integration setup canceled.", {
      variant: "info",
    });
    setOauthDialogOpen(false);
    setSelectedIntegration(null);
  };

  const renderAdditionalSettings = () => {
    /*
    Render additional settings for Salesforce
    */
    if (integration?.provider === "salesforce") {
      return (
        <FormControlLabel
          sx={{
            paddingLeft: "8px",
            paddingY: "16px",
          }}
          control={
            <Switch
              checked={additionalSettings?.sandboxChecked ?? false}
              name="subdomain"
              onChange={(event) => {
                const { checked } = event.target;
                setAdditionalSettings({ sandboxChecked: checked });
              }}
              color="secondary"
            />
          }
          label="Connect to sandbox account"
        />
      );
    }
    return null;
  };

  const renderAuthorizeButton = () => {
    /*
    Render authorize button for Salesforce
    */
    if (integration?.provider === "salesforce") {
      return (
        <AuthorizeSalesforceButton
          additionalSettings={additionalSettings}
          currentAccount={currentAccount}
          setOauthDialogOpen={setOauthDialogOpen}
          setSelectedIntegration={setSelectedIntegration}
        />
      );
    }
    /*
    Render authorize button for Job Adder
    */
    if (integration?.provider === "job_adder") {
      return (
        <AuthorizeJobAdderButton
          currentAccount={currentAccount}
          setOauthDialogOpen={setOauthDialogOpen}
          setSelectedIntegration={setSelectedIntegration}
        />
      );
    }
    /*
    Render authorize button for Hubspot
    */
    if (integration?.provider === "hubspot") {
      return (
        <AuthorizeHubspotButton
          currentAccount={currentAccount}
          integration={integration}
          setOauthDialogOpen={setOauthDialogOpen}
          setSelectedIntegration={setSelectedIntegration}
        />
      );
    }
    return (
      <Button
        color="primary"
        endIcon={<OpenInNewIcon />}
        variant="contained"
        sx={{
          padding: "6px 16px",
        }}
      >
        Authorize
      </Button>
    );
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={() => {
        return handleCloseModal();
      }}
    >
      <DialogHeader
        title={`Authenticate ${integration?.providerName ?? ""}`}
        onClose={() => {
          return handleCloseModal();
        }}
      />
      <DialogContent
        sx={{
          padding: "16px",
        }}
      >
        <Typography variant="body1">
          Sign into your {integration?.providerName ?? ""} account to authorize
          this connection.
        </Typography>
        {renderAdditionalSettings()}
      </DialogContent>
      <Box display="flex" flexDirection="column" maxHeight="10rem">
        <Divider />
        <Box
          display="flex"
          justifyContent="space-between"
          padding="1rem"
          width="100%"
          sx={(theme) => {
            return {
              [theme.breakpoints.down("sm")]: {
                flexFlow: "column nowrap",
                gap: "16px",
              },
            };
          }}
        >
          <Box
            display="flex"
            gap={1}
            flexDirection="row"
            sx={{
              flex: "1 1 auto",
              justifyContent: "flex-end",
            }}
          >
            <Button
              onClick={() => {
                return handleCloseModal();
              }}
              data-testid="cancel"
              sx={{
                padding: "6px 8px",
              }}
            >
              Cancel
            </Button>

            {renderAuthorizeButton()}
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}
