import { useCallback, useEffect, useState } from "react";
import {
  Alert,
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  styled,
} from "@mui/material";
import { Field } from "formik";
import formatDuration from "date-fns/formatDuration";
import { KeywordFormSequenceSelectProps } from "../types";
import { SequenceDropdownSearchBar } from "features/Sequences/components/SequenceDropdownSearchBar";
import { fetchSequenceSteps } from "features/Sequences/api";

const StyledCheckbox = styled(Checkbox)(({ theme }) => {
  return {
    height: "40px",
    width: "40px",
    "&.Mui-checked": {
      color: theme.palette.lightBlue[800],
    },
  };
});

function KeywordFormSequenceSelect({
  account,
  keyword,
  enrollInSequence,
  enrollInSequenceFieldName = "enrollInSequence",
  sequenceFieldName = "sequenceId",
  sequenceId,
  setSequenceId,
  setFieldValue,
  sequences,
  searchWidth = "546px",
}: KeywordFormSequenceSelectProps) {
  const [sequenceSteps, setSequenceSteps] = useState([]);

  const fetchSequenceStepList = useCallback(async () => {
    const response = await fetchSequenceSteps(account, sequenceId);
    const body = await response.json();

    setSequenceSteps(body);
  }, [account, sequenceId]);

  useEffect(() => {
    if (sequenceId) {
      fetchSequenceStepList();
    }
  }, [sequenceId, fetchSequenceStepList]);

  const onChange = (event) => {
    if (!event.target.checked) {
      setFieldValue(sequenceFieldName, "");
    }

    setFieldValue(enrollInSequenceFieldName, event.target.checked);
  };

  const firstStepDelay = sequenceSteps[0]?.relativeDelay || {
    days: 0,
    hours: 0,
    minutes: 5,
  };

  return (
    <Box>
      <Typography variant="h6" sx={{ paddingLeft: "8px", height: "36px" }}>
        After this message is sent:
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Field
          type="checkbox"
          name={enrollInSequenceFieldName}
          render={({ field }) => {
            return (
              <FormControlLabel
                label="Enroll in sequence"
                sx={{ margin: "0px", height: "40px" }}
                control={
                  <StyledCheckbox
                    {...field}
                    name={enrollInSequenceFieldName}
                    checked={enrollInSequence}
                    onChange={onChange}
                  />
                }
              />
            );
          }}
        />
        <Box>
          {enrollInSequence && (
            <SequenceDropdownSearchBar
              sequences={sequences}
              setSequenceId={setSequenceId}
              fieldName={sequenceFieldName}
              setFieldValue={setFieldValue}
              searchWidth={searchWidth}
            />
          )}
          {keyword && sequenceId && (
            <Alert severity="info" sx={{ marginTop: "8px" }}>
              <div>
                Step #1 will be sent{" "}
                <Box component="span" sx={{ fontWeight: 700 }}>
                  {formatDuration(firstStepDelay, { delimiter: ", " })}
                </Box>{" "}
                after responding with {keyword}
              </div>{" "}
            </Alert>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default KeywordFormSequenceSelect;
