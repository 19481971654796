import { useMemo, useState } from "react";

import { Box, Button, Divider, Tabs, Tab } from "@mui/material";

import { formatDistanceToNowStrict } from "date-fns";

import { SequencesDataGrid } from "../../components/SequencesDataGrid";
import { SequenceValues } from "../../hooks/useSequences/types";
import { CreateSequenceModal } from "../../components/CreateSequenceModal";
import { MODAL_SELECTIONS } from "../../components/CreateSequenceModal/constants";
import {
  DuplicateSequenceDrawer,
  SelectSequenceDrawer,
} from "../../components/Drawers";
import { SEQUENCE_STATES } from "../IndividualSequenceOverview/constants";
import { SequencesProps } from "./types";
import { Sequence as SequenceType } from "models/Sequence";

import { ArchiveSequenceDialog } from "features/Sequences/components/ArchiveSequenceDialog";
import { RefreshTimer } from "features/Sequences/components/RefreshTimer";
import { Dialog, useDialog } from "components/Dialog";
import PageHeader from "components/Page/PageHeader";
import PageContent from "components/Page/PageContent";
import { SearchBox } from "components/SearchBox";

import { useActivateSequence } from "features/Sequences/hooks/useActivateSequence";
import { useArchiveSequenceDialog } from "features/Sequences/hooks/useArchiveSequence";
import { useDuplicateSequence } from "features/Sequences/hooks/useDuplicateSequence";
import { useRefreshTimer } from "features/Sequences/hooks/useRefreshTimer";
import { useSequences } from "features/Sequences/hooks/useSequences";
import { useSequenceSteps } from "features/Sequences/hooks/useSequenceSteps";

function Sequences({
  currentAccount,
  isSinglePanel,
  selectedNavLink,
  returnPath,
  showSidebar,
  toggleSidebar,
}: SequencesProps) {
  // ==== FIELDS ==== //
  const { active, archive, inactive } = SEQUENCE_STATES;
  const { initial, duplicate } = MODAL_SELECTIONS;

  // ==== HOOKS ==== //
  const {
    allSequences,
    debouncedSearchTerm,
    isLoadingSequence,
    searchTerm,
    selectedTab,
    sequenceCounts,
    sequenceValues,
    fetchAllSequences,
    getDisplayedSequences,
    getSequences,
    getSequenceCounts,
    handleClear,
    handleCreateSequence,
    handleSearch,
    handleTabChange,
    setSequenceValues,
  } = useSequences(currentAccount, selectedNavLink);

  const { sequenceSteps, getSequenceSteps } = useSequenceSteps(currentAccount);

  const { lastRefreshTimestamp, updateRefreshTimestamp } = useRefreshTimer();

  const {
    active: activeCount,
    archive: archiveCount,
    inactive: inactiveCount,
  } = sequenceCounts;

  const { ref: dialogRef, open: openDialog } = useDialog<
    any,
    SequenceValues | false
  >();

  const [selectSequenceDrawerIsOpen, setSelectSequenceDrawerIsOpen] =
    useState<boolean>(false);
  const [duplicateSequenceDrawerIsOpen, setDuplicateSequenceDrawerIsOpen] =
    useState<boolean>(false);
  const [selectedSequence, setSelectedSequence] = useState<SequenceType>();

  const {
    duplicateSequenceDialogRef,
    isLoadingDuplicate,
    handleDuplicateSequenceClick,
  } = useDuplicateSequence(returnPath);

  const { archiveSequenceDialogRef, openArchiveSequenceDialog } =
    useArchiveSequenceDialog();

  const { isLoadingActivate, handleActivateSequence } = useActivateSequence();

  // ==== METHODS ==== //
  const displayedSequenceList = useMemo(() => {
    // converts Date string to "6 hours ago" format
    const displayedSequences = getDisplayedSequences();
    return displayedSequences.map((sequence) => {
      return {
        ...sequence,
        updatedAt: formatDistanceToNowStrict(new Date(sequence.updatedAt), {
          addSuffix: true,
        }),
      };
    });
  }, [getDisplayedSequences]);

  const handleSelectSequence = (sequence?: SequenceType): void => {
    setSelectSequenceDrawerIsOpen(false);
    setSelectedSequence(sequence);

    if (sequence?.id) {
      getSequenceSteps(sequence.id);
      setDuplicateSequenceDrawerIsOpen(true);
    }
  };

  const refreshSequences = (): void => {
    getSequences();
    getSequenceCounts();
    setSelectedSequence(undefined);
    updateRefreshTimestamp();
  };

  // ==== RENDER ==== //
  return (
    <PageContent isSinglePanel={isSinglePanel} showSidebar={showSidebar}>
      <PageHeader
        className="sequences-page-header"
        aria-label="Sequences Overview"
        title="Sequences"
        toggleSidebar={toggleSidebar}
      >
        <RefreshTimer
          lastRefreshTimestamp={lastRefreshTimestamp}
          clickHandler={refreshSequences}
        />
      </PageHeader>
      <Box
        display="flex"
        flexDirection="row"
        width="100%"
        justifyContent="space-between"
      >
        <Box width="25%" margin={1}>
          <SearchBox
            value={searchTerm}
            onClear={handleClear}
            onChange={handleSearch}
            placeholder="Search sequences"
            size="small"
          />
        </Box>
        <Box
          alignItems="center"
          display="flex"
          justifyContent="center"
          marginRight="20px"
        >
          <Button
            aria-label="Create New Sequence Button"
            data-testid="create-new-sequence-button"
            onClick={openDialog({}, (values) => {
              if (values) {
                handleCreateSequence(values);
              } else {
                // Resets sequence values in the case where the flow is canceled.
                setSequenceValues({
                  finishedOnReply: "",
                  sequenceName: "",
                  scheduleType: "",
                });
              }
            })}
            size="large"
            variant="contained"
          >
            Create New
          </Button>
        </Box>
      </Box>
      <Divider />
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        sx={{ paddingLeft: "1rem" }}
      >
        <Tab data-testid={active} label={`${active} (${activeCount})`} />
        <Tab data-testid={inactive} label={`${inactive} (${inactiveCount})`} />
        <Tab data-testid={archive} label={`${archive} (${archiveCount})`} />
      </Tabs>

      <SequencesDataGrid
        isLoading={isLoadingSequence || isLoadingDuplicate || isLoadingActivate}
        rows={displayedSequenceList}
        isSearchEntered={debouncedSearchTerm !== ""}
        selectedTab={selectedTab}
        handleArchiveSequenceClick={(sequence) => {
          openArchiveSequenceDialog(sequence, () => {
            refreshSequences();
          });
        }}
        handleDuplicateSequenceClick={handleDuplicateSequenceClick}
      />

      {/* ==== DIALOGS ==== */}
      <Dialog<any, SequenceValues | false> ref={dialogRef} maxWidth="md">
        {({ close, parameters }) => {
          return (
            <CreateSequenceModal
              close={close}
              initialSelection={parameters?.initialSelection || initial}
              sequenceValues={sequenceValues}
              handleDuplicateSequenceClick={handleDuplicateSequenceClick}
              openDuplicateDrawer={() => {
                fetchAllSequences();
                setSelectSequenceDrawerIsOpen(true);
              }}
            />
          );
        }}
      </Dialog>

      <Dialog<any, any> ref={duplicateSequenceDialogRef}>
        {({ close, parameters: sequence = {} }) => {
          return (
            <CreateSequenceModal
              close={close}
              handleBack={() => {
                handleSelectSequence(selectedSequence);
              }}
              initialSelection={duplicate}
              sequenceValues={{
                sequenceName: `Copy of ${sequence.name}`,
                scheduleType: sequence.scheduleType,
                finishedOnReply: String(sequence.finishedOnReply),
                useCase: sequence.useCase,
              }}
            />
          );
        }}
      </Dialog>

      <Dialog<any, boolean> ref={archiveSequenceDialogRef}>
        {({ close }) => {
          return (
            <ArchiveSequenceDialog close={close} refresh={refreshSequences} />
          );
        }}
      </Dialog>

      {/* ==== DRAWERS ==== */}
      <SelectSequenceDrawer
        isOpen={selectSequenceDrawerIsOpen}
        sequences={allSequences}
        handleSelectSequence={handleSelectSequence}
        handleClose={() => {
          handleSelectSequence(undefined);
        }}
      />

      {selectedSequence && (
        <DuplicateSequenceDrawer
          isOpen={duplicateSequenceDrawerIsOpen}
          sequence={selectedSequence}
          sequenceSteps={sequenceSteps}
          handleBack={() => {
            handleSelectSequence(undefined);
            setSelectSequenceDrawerIsOpen(true);
            setDuplicateSequenceDrawerIsOpen(false);
          }}
          handleConfirm={() => {
            handleDuplicateSequenceClick(selectedSequence);
            setDuplicateSequenceDrawerIsOpen(false);
          }}
          handleClose={() => {
            handleSelectSequence(undefined);
            setDuplicateSequenceDrawerIsOpen(false);
          }}
        />
      )}
    </PageContent>
  );
}

export { Sequences };
