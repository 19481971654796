import { schema } from "normalizr";

const contactTags = new schema.Entity("contactTags", {});
const contactNote = new schema.Entity("contactNote", {});
const contactPhone = new schema.Entity("contactPhone");

const contactPhoneCollection = new schema.Entity("contactPhoneCollection", {
  members: [contactPhone],
});

const contactNoteCollection = new schema.Entity("contactNoteCollection", {
  members: [contactNote],
});

const contact = new schema.Entity("contacts", {
  phones: contactPhoneCollection,
  notes: contactNoteCollection,
});

const contactCollection = new schema.Entity("contactCollection", {
  members: [contact],
});

contactPhone.define({ contact });

const contactFilter = new schema.Entity("contactFilter", {
  contacts: contactCollection,
});

const contactFilterCollection = new schema.Entity("contactFilterCollection", {
  members: [contactFilter],
});

const group = new schema.Entity("group", {
  contacts: contactCollection,
});

const groupCollection = new schema.Entity("groupCollection", {
  members: [group],
});

const groupFilter = new schema.Entity("groupFilter", {});

const groupFilterCollection = new schema.Entity("groupFilterCollection", {
  members: [groupFilter],
});

const customField = new schema.Entity("customField", {});

const customFieldCollection = new schema.Entity("customFieldCollection", {
  members: [customField],
});

const userSchema = new schema.Entity("userSchema", {
  fields: customFieldCollection,
});

const userPersonalization = new schema.Entity("userPersonalizationValue", {});

const deliveryStats = new schema.Entity("deliveryStats");

const accountChildrenCollection = new schema.Entity("account");

const account = new schema.Entity("account", {
  children: accountChildrenCollection,
});

accountChildrenCollection.define({ members: [account] });

const accountCollection = new schema.Entity("accountCollection", {
  members: [account],
});

const accountTreeCollection = new schema.Entity("accountTreeCollection", {});

const membership = new schema.Entity("membership", {
  account,
});

const membershipsCollection = new schema.Entity("membershipsCollection", {
  members: [membership],
});

const savedReply = new schema.Entity("savedReply", {});

const savedReplyCollection = new schema.Entity("savedReplyCollection", {
  members: [savedReply],
});

const signature = new schema.Entity("signature", {});

const gettingStartedElement = new schema.Entity("gettingStartedElement", {});

const gettingStartedElementCollection = new schema.Entity(
  "gettingStartedElementCollection",
  { members: [gettingStartedElement] },
);

const user = new schema.Entity("users", {
  memberships: membershipsCollection,
  signature,
  gettingStartedElements: gettingStartedElementCollection,
});

const userCollection = new schema.Entity("userCollection", {
  members: [user],
});

account.define({ users: userCollection });
contactNote.define({ user });

const attachment = new schema.Entity("attachment", {});

const attachmentCollection = new schema.Entity("attachmentCollection", {
  members: [attachment],
});

const message = new schema.Entity("messages", {
  sender: user,
  attachments: attachmentCollection,
});

const badge = new schema.Entity("badge");

const badgeCollection = new schema.Entity("userCollection", {
  members: [badge],
});

const siteNavigationElement = new schema.Entity("siteNavigationElement");

const siteNavigation = new schema.Entity("siteNavigation", {
  members: [siteNavigationElement],
});

const accountNavbarElement = new schema.Entity("accountNavbarElement", {
  siteNavigation,
  badges: badgeCollection,
});

const accountNavbar = new schema.Entity("accountNavbar", {
  members: [accountNavbarElement],
});

const bootstrap = new schema.Entity("bootstrap", {
  currentUser: user,
  accountNavbar,
  badges: badgeCollection,
});

const requestingUserInfo = new schema.Entity("requestingUserInfo", {});

const participantsCollection = new schema.Entity("participantsCollection", {
  members: [user],
});

const conversationAssignment = new schema.Entity("conversationAssignment", {
  assignee: user,
  assigner: user,
});

const conversationAssignmentsCollection = new schema.Entity(
  "conversationAssignmentsCollection",
  {
    members: [conversationAssignment],
  },
);

const conversationEvent = new schema.Entity("ConversationEvent", {
  user,
});

const timelineMembers = new schema.Array(
  {
    Message: message,
    ConversationEvent: conversationEvent,
    ContactNote: contactNote,
  },
  (value) => {
    return value["@type"];
  },
);

const timeline = new schema.Entity("timeline", {
  members: timelineMembers,
});

const conversationStats = new schema.Entity("conversationStats");

const latestPreviewableItem = new schema.Union(
  {
    Message: message,
  },
  (value) => {
    return value["@type"];
  },
);

const conversation = new schema.Entity("conversations", {
  account,
  assignedContact: contact,
  associatedContacts: contactCollection,
  requestingUserInfo,
  participants: participantsCollection,
  conversationAssignments: conversationAssignmentsCollection,
  timeline,
  latestPreviewableItem,
  nextScheduledMessage: message,
  stats: conversationStats,
});

const conversationCollection = new schema.Entity("conversationCollection", {
  members: [conversation],
});

const conversationFilter = new schema.Entity("conversationFilter", {
  conversations: conversationCollection,
});

const conversationFilterCollection = new schema.Entity(
  "conversationFilterCollection",
  { members: [conversationFilter] },
);

conversationFilter.define({ subFilters: conversationFilterCollection });

const inbox = new schema.Entity("inbox", {
  conversationFilters: conversationFilterCollection,
  activeConversationFilter: conversationFilter,
  activeConversation: conversation,
});

const inboxWithUrlsOnly = new schema.Entity("inbox", {});

const contactImport = new schema.Entity("contactImport", {});

const contactImportCollection = new schema.Entity("contactImportCollection", {
  members: [contactImport],
});

const conversationImport = new schema.Entity("conversationImport", {});

const conversationImportCollection = new schema.Entity(
  "conversationImportCollection",
  {
    members: [conversationImport],
  },
);

const campaignRecipient = new schema.Entity("campaignRecipient", {
  contactPhone,
});

const campaignRecipientCollection = new schema.Entity(
  "campaignRecipientCollection",
  {
    members: [campaignRecipient],
  },
);

const campaign = new schema.Entity("campaign", {
  user,
  totalRecipients: campaignRecipientCollection,
  skippedRecipients: campaignRecipientCollection,
  sentRecipients: campaignRecipientCollection,
  deliveredRecipients: campaignRecipientCollection,
  failedRecipients: campaignRecipientCollection,
  repliedRecipients: campaignRecipientCollection,
  recipients: campaignRecipientCollection,
});

const campaignCollection = new schema.Entity("campaignCollection", {
  members: [campaign],
});

const campaignFilter = new schema.Entity("campaignFilter", {
  campaigns: campaignCollection,
});

const campaignFilterCollection = new schema.Entity("campaignFilterCollection", {
  members: [campaignFilter],
});

const accountAnalytics = new schema.Entity("accountAnalytics", {
  account,
});
const accountAnalyticsCollection = new schema.Entity(
  "accountAnalyticsCollection",
  {
    members: [accountAnalytics],
  },
);

const analyticsReports = new schema.Entity("analyticsReports", {});

const analyticsReport = new schema.Entity("analyticsReport", {});

const analyticsReportsList = new schema.Entity("analyticsReportsList", {
  members: [analyticsReport],
});

const accountAggregateStats = new schema.Entity("accountAggregateStats", {});

const accountPlan = new schema.Entity("accountPlans", {});

const accountPlans = new schema.Entity("accountPlans", {
  members: [accountPlan],
});

const automationKeyword = new schema.Entity("automationKeyword", {});

const automationKeywords = new schema.Entity("automationKeywords", {
  members: [automationKeyword],
});

const userAnalytics = new schema.Entity("userAnalytics", {
  user,
});
const userAnalyticsCollection = new schema.Entity("userAnalyticsCollection", {
  members: [userAnalytics],
});

const userApiToken = new schema.Entity("userApiToken", {});
const userApiTokenCollection = new schema.Entity("userApiTokenCollection", {
  members: [userApiToken],
});

const integration = new schema.Entity("integration", {});

const integrationCollection = new schema.Entity("integrationCollection", {
  members: [integration],
});

const analyticExport = new schema.Entity("analyticExport", {});

const analyticExportReports = new schema.Entity("analyticExportReports", {});

const scheduleExports = new schema.Entity("scheduleExports", {});

const campaignAnalytics = new schema.Entity("campaignAnalytics", {});

const optOutAnalytics = new schema.Entity("optOutAnalytics", {});

const optOuts = new schema.Entity("optOuts", {});

const optOut = new schema.Entity("optOut", {
  originatingAccount: account,
  user,
});

const optOutsCollection = new schema.Entity("optOutsCollection", {
  members: [optOut],
});

const avatar = new schema.Entity("avatar");

const accountHierarchy = new schema.Entity("accountHierarchy", {
  members: [account],
});

export {
  account,
  accountAggregateStats,
  accountAnalytics,
  accountAnalyticsCollection,
  accountChildrenCollection,
  accountCollection,
  accountHierarchy,
  accountNavbar,
  accountNavbarElement,
  accountPlan,
  accountPlans,
  accountTreeCollection,
  analyticExport,
  analyticExportReports,
  analyticsReports,
  analyticsReportsList,
  automationKeyword,
  automationKeywords,
  scheduleExports,
  avatar,
  badge,
  badgeCollection,
  campaignAnalytics,
  conversationAssignment,
  conversationAssignmentsCollection,
  campaign,
  campaignCollection,
  campaignFilter,
  campaignFilterCollection,
  campaignRecipient,
  campaignRecipientCollection,
  contact,
  contactPhone,
  contactNote,
  contactNoteCollection,
  contactTags,
  contactCollection,
  contactFilter,
  contactFilterCollection,
  contactImport,
  contactImportCollection,
  contactPhoneCollection,
  conversation,
  conversationStats,
  conversationImport,
  conversationImportCollection,
  conversationCollection,
  conversationFilter,
  conversationFilterCollection,
  customField,
  customFieldCollection,
  deliveryStats,
  bootstrap,
  group,
  groupCollection,
  groupFilter,
  groupFilterCollection,
  inbox,
  inboxWithUrlsOnly,
  integration,
  integrationCollection,
  message,
  optOutAnalytics,
  optOuts,
  optOutsCollection,
  savedReply,
  savedReplyCollection,
  signature,
  siteNavigation,
  timeline,
  user,
  userCollection,
  userAnalytics,
  userAnalyticsCollection,
  userApiToken,
  userApiTokenCollection,
  userPersonalization,
  userSchema,
};
