import { IconButton, Menu, MenuItem } from "@mui/material";
import { MouseEvent, useState } from "react";
import { capitalize, upperCase } from "lodash";
import { enqueueSnackbar } from "notistack";
import { useHistory } from "react-router";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { archiveKeywords, updateKeywords } from "../api";
import { MoreVertIcon } from "icons";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import { useCurrentAccount } from "hooks";
import { Keyword } from "models/Keyword";

interface KeywordTableActionMenuProps {
  actionType: "archive" | "reactivate";
  keyword: Keyword;
  refresh: () => Promise<void>;
  params: GridRenderCellParams;
}

const renderSnackbar = (success: boolean, verb: string) => {
  return success // response.ok
    ? enqueueSnackbar(`Keyword automation successfully ${verb}.`, {
        variant: "info",
      })
    : enqueueSnackbar("Something went wrong. Please try again.", {
        variant: "error",
      });
};

function KeywordTableActionMenu({
  actionType,
  keyword,
  refresh,
  params,
}: KeywordTableActionMenuProps) {
  /* HOOKS */
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const currentAccount = useCurrentAccount();

  /* VARIABLES */
  const activeKeywords = actionType === "archive";
  const title = `${capitalize(actionType)} keyword`;
  const actionPastParticiple = activeKeywords ? "archived" : "reactivated";
  const actionPresentParticiple = activeKeywords ? "archiving" : "reactivating";

  const modalBody = activeKeywords
    ? "Archiving a keyword will deactivate the automation. Incoming messages containing the keyword will no longer receive the auto-response. Keywords can be reactivated after archiving."
    : "Reactivating a keyword will allow inbound messages containing the keyword to receive the auto-response. This keyword will move from the archive to active page.";

  const localStorageKey = `HIDE_${upperCase(actionType)}_KEYWORD_MODAL`;
  const skipDialog = localStorage.getItem(localStorageKey) === "true";

  /* FUNCTIONS */
  const handleOpenMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const handleConfirm = async () => {
    // TODO: This will also need to accept an array of keywordIds
    if (activeKeywords) {
      return archiveKeywords(currentAccount, keyword.id).then((response) => {
        renderSnackbar(response.ok, actionPastParticiple);
        return refresh();
      });
    }
    return updateKeywords(currentAccount, keyword.id, { active: true }).then(
      (response) => {
        renderSnackbar(response.ok, actionPastParticiple);
        return refresh();
      },
    );
  };

  const handleOpenModal = () => {
    if (skipDialog) {
      handleConfirm().catch((error) => {
        // Error snackbar is rendered inside handleConfirm() function
        // This is an added layer for devs to see the error in console
        console.error(
          `Error occurred trying to ${actionType} keyword: ${error}`,
        );
      });
    } else {
      setIsModalOpen(true);
    }

    setMenuAnchorEl(null);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const history = useHistory();

  const handleManageClick = () => {
    history.push(`${window.location.pathname}/${params?.id}`);
  };

  return (
    <>
      <IconButton onClick={handleOpenMenu}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {/* TODO: Add onCLick handlers to MenuItems */}
        <MenuItem onClick={handleManageClick}>Manage keyword</MenuItem>
        <MenuItem>View subscribers</MenuItem>
        <MenuItem onClick={handleOpenModal}>{title}</MenuItem>
      </Menu>
      <ConfirmationModal
        title={`${title}?`}
        message={modalBody}
        buttonText={capitalize(actionType)}
        cancellationHandler={handleCloseModal}
        confirmationHandler={handleConfirm}
        confirmStyles={
          activeKeywords ? { color: "error", variant: "text" } : undefined
        }
        doNotAskAgainText={`${actionPresentParticiple} a keyword`}
        isOpen={isModalOpen}
        localStorageKey={localStorageKey}
      />
    </>
  );
}

export default KeywordTableActionMenu;
