import PropTypes from "prop-types";
import Box from "@mui/material/Box";

function AccountHeaderRow({ nameOnly, brandInformation }) {
  return (
    <Box
      aria-label="Account Section Header"
      borderColor="divider"
      display="flex"
      flexDirection="row"
      fontSize="body2.fontSize"
      fontWeight="500"
      height="56px"
      py={2}
      pl={2}
      sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
    >
      <Box
        key="name"
        sx={{
          flex: "auto",
          flexGrow: 1,
          flexShrink: 1,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {nameOnly ? "Account" : "Name"}
      </Box>
      {!nameOnly && (
        <Box
          key="numbers"
          sx={{
            flex: "204px",
            flexGrow: 0,
            flexShrink: 0,
            textAlign: "left",
          }}
        >
          Phone Numbers
        </Box>
      )}
      {!nameOnly && !brandInformation && (
        <>
          <Box
            key="children"
            sx={{
              flex: "112px",
              flexGrow: 0,
              flexShrink: 0,
              textAlign: "left",
            }}
          >
            Accounts
          </Box>
          <Box
            key="users"
            sx={{
              flex: "112px",
              flexGrow: 0,
              flexShrink: 0,
              textAlign: "left",
            }}
          >
            Users
          </Box>
          <Box
            key="manage"
            sx={{
              flex: "112px",
              flexGrow: 0,
              flexShrink: 0,
              textAlign: "left",
            }}
          >
            Manage
          </Box>
        </>
      )}
    </Box>
  );
}

AccountHeaderRow.propTypes = {
  brandInformation: PropTypes.bool,
  nameOnly: PropTypes.bool.isRequired,
};

export default AccountHeaderRow;
