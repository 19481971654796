/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const UPDATE_APP_COLORS = "app/App/UPDATE_APP_COLORS";
export const UPDATE_CURRENT_ACCOUNT = "app/App/UPDATE_CURRENT_ACCOUNT";
export const UPDATE_EXTRACTED_PHONES = "app/App/UPDATE_EXTRACTED_PHONES";
export const UPDATE_USER_CAN_HOVER = "app/App/UPDATE_USER_CAN_HOVER";
export const UPDATE_RECORDS = "app/App/UPDATE_RECORDS";
export const SET_LAST_ACTION = 'app/App/SET_LAST_ACTION';
export const CLEAR_LAST_ACTION = 'app/App/CLEAR_LAST_ACTION';
