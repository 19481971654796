import { DefaultRootState, useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { Contact } from "@tesseract/core";
import { normalize } from "normalizr";

import { fetchContactCollection } from "../api/fetchContactCollection";
import * as schema from "schema";
import actionTypes from "features/PusherComponent/actionTypes";
import {
  CLEAR_LAST_ACTION,
  UPDATE_RECORDS,
} from "features/EntryPoint/containers/App/constants";
import denormalizeWithShape from "utils/denormalizeWithShape";

interface Props {
  contactCollectionId: string;
}

function useContactCollection({ contactCollectionId }: Props) {
  const [contactCollection, setContactCollection] = useState<
    Contact.Collection | Record<string, undefined>
  >({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const dispatch = useDispatch();
  const globalState = useSelector((state: DefaultRootState & { get: any }) => {
    return {
      ...state.get("global").toJS(),
    };
  });

  // Some write operations are actually queued as a job on the server and not immediately reflected in the data.
  // Once the server has completed all write operations, it dispatches an event to notify the front end.
  // This effect listens for that event, and updates the exposed data from the redux store.
  useEffect(() => {
    const { CONTACT_COLLECTION_UPDATED } = actionTypes as Record<
      string,
      string
    >;

    // Check redux store for the server event we care about.
    // It is important to understand that this event is only dispatched after the client has finished updating the store
    if (globalState.lastAction?.includes(CONTACT_COLLECTION_UPDATED)) {
      const shape = {
        members: [{ phones: { members: [] } }],
      };

      // munge the data into the shape the components expect
      const denormalized = denormalizeWithShape({
        id: contactCollectionId,
        records: globalState.records,
        shape,
      });

      // update the hook data
      setContactCollection(denormalized);

      // clear the last action from the redux store to terminate this event loop
      dispatch({
        type: CLEAR_LAST_ACTION,
      });
    }
  }, [globalState, contactCollectionId, dispatch]);

  // fetch contact collection if needed
  const sendFetchRequest = useCallback(async () => {
    if (!contactCollectionId) return;

    setIsLoading(true);
    const res = await fetchContactCollection(contactCollectionId);
    const data = await res.json();

    // update data for use in component
    setContactCollection(data);

    // normalize data & dispatch to redux store
    const { entities } = normalize(data, schema.contactCollection);
    
    setIsLoading(false);

    dispatch({
      type: UPDATE_RECORDS,
      records: entities,
    });
  }, [contactCollectionId, dispatch]);

  useEffect(() => {
    void sendFetchRequest();
  }, [sendFetchRequest, contactCollectionId]);

  return {
    contactCollection,
    sendFetchRequest,
    isLoading,
    isError,
    error,
  };
}

export { useContactCollection };
