/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import {
  DateValidationError,
  DesktopDatePicker,
  DesktopTimePicker,
  LocalizationProvider,
  TimeValidationError,
} from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import {
  addMinutes,
  addMonths,
  isSameDay,
  isToday,
  subMinutes,
} from "date-fns";
import { useField } from "formik";
import { AbsoluteTimePickerProps } from "../types";
import { useTimeZones } from "hooks";

function AbsoluteTimePicker({
  existingDate,
  isInitialStep,
  nextStepDate,
  previousStepDate,
  values,
  setFieldValue,
  setScheduleError,
}: AbsoluteTimePickerProps) {
  const [loaded, setLoaded] = useState<any>(false);
  const [dateError, setDateError] = useState<DateValidationError | undefined>();
  const [timeError, setTimeError] = useState<TimeValidationError | undefined>();

  const { accountTimeZone } = useTimeZones();
  const dateField = useField("absoluteTime");

  // If initialValues.absoluteTime (existingDate) is not null, the step is being edited.
  const isEditing = Boolean(existingDate);

  const currentDaySelected = values.absoluteTime
    ? isToday(values.absoluteTime)
    : false;

  const previousStepDaySelected =
    values.absoluteTime && previousStepDate
      ? isSameDay(values.absoluteTime, previousStepDate)
      : false;

  const minDate = previousStepDate
    ? addMinutes(previousStepDate, 10)
    : addMinutes(new Date(), 10);

  const minTime =
    currentDaySelected || previousStepDaySelected
      ? subMinutes(minDate, 5)
      : undefined;

  const getTimeErrorText = (
    initialStep: boolean,
    error: TimeValidationError,
  ) => {
    if (initialStep) {
      return "Must be more than 5 minutes from now.";
    }
    // Will be "maxTime" or "minTime"
    return error === "minTime"
      ? "Must be 5 minutes after previous step."
      : "Must be before next step.";
  };

  const dateErrorText =
    // Will be "maxDate" or "minDate"
    dateError === "minDate"
      ? "Must occur in the future or after previous step."
      : `Must occur before next step and within 18 months.`;

  const handleDateChange = (newScheduledAt: Date | null) => {
    setFieldValue("absoluteTime", newScheduledAt).catch((error) => {
      console.error(error);
    });
  };

  useEffect(() => {
    if (isEditing) {
      // Sets to already selected time if editing step.
      setFieldValue("absoluteTime", existingDate).catch((error) => {
        console.error(error);
      });
    }

    setLoaded(true);
  }, [previousStepDate, nextStepDate]);

  useEffect(() => {
    if (dateError || timeError) {
      return setScheduleError(true);
    }
    return setScheduleError(false);
  }, [dateError, timeError]);

  // Ensures pickers are updated before loading Autocomplete component.
  // Otherwise, an error gets thrown related to trying to update an uncontrolled component.
  if (!loaded) {
    return null;
  }

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="flex-start"
      justifyContent="space-between"
      gap="0.5rem"
      width="100%"
      marginTop="1rem"
      marginBottom="0.25rem"
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          disablePast
          format="EEEE, LLL do, yyyy"
          minDate={minDate}
          label="Date"
          maxDate={nextStepDate || addMonths(new Date(), 18)}
          onChange={handleDateChange}
          onError={(error) => {
            setDateError(error);
          }}
          slotProps={{
            textField: {
              ...dateField,
              variant: "outlined",
              sx: { width: "340px" },
              helperText: dateError ? dateErrorText : " ",
            },
          }}
          timezone={accountTimeZone}
          value={values.absoluteTime}
        />
      </LocalizationProvider>

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopTimePicker
          minTime={minTime}
          maxTime={
            nextStepDate && isSameDay(values.absoluteTime, nextStepDate)
              ? nextStepDate
              : undefined
          }
          label="Time"
          onChange={handleDateChange}
          onError={(error) => {
            setTimeError(error);
          }}
          slotProps={{
            textField: {
              ...dateField,
              variant: "outlined",
              sx: { width: "340px" },
              helperText: timeError
                ? getTimeErrorText(isInitialStep, timeError)
                : " ",
            },
          }}
          timezone={accountTimeZone}
          data-testid={values.absoluteTime}
          value={values.absoluteTime}
        />
      </LocalizationProvider>
    </Box>
  );
}
export default AbsoluteTimePicker;
