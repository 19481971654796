/* eslint-disable no-shadow */
import { useState } from "react";
import { useSnackbar } from "notistack";
import { styled } from "@mui/material";
import { Link } from "react-router-dom";
import { useCurrentAccount } from "./useCurrentAccount";
import { Snackbar, SnackbarProps } from "components/Snackbar";

export enum FindNumbersImportState {
  Default,
  Importing,
  NoContactsFound,
  Error,
}

const LinkText = styled(Link)(({ theme }) => {
  return {
    color: `${theme.palette.secondary.dark}`,
    fontSize: "14px",
    textDecoration: "none",
  };
});

export const useFindNumbers = () => {
  // ==== HOOKS ==== //
  const { slug } = useCurrentAccount();
  const { enqueueSnackbar } = useSnackbar();
  const [importState, setImportState] = useState<FindNumbersImportState>(
    FindNumbersImportState.Default,
  );

  // ==== METHODS ==== //
  const showNotification = (
    message: string,
    variant: SnackbarProps["variant"],
    action?: JSX.Element,
  ) => {
    enqueueSnackbar(message, {
      content: (key, msg) => {
        return (
          <Snackbar id={key} message={msg} variant={variant} action={action} />
        );
      },
    });
  };

  const showSuccessNotification = (contactImportResponse: any) => {
    const contactImportId = contactImportResponse.id.split("/")[2];
    const numImported =
      contactImportResponse.recordCount - contactImportResponse.failureCount;

    const viewButton = (
      <LinkText to={`/${slug}/contacts/import:${contactImportId}`}>
        VIEW
      </LinkText>
    );
    showNotification(`${numImported} numbers imported.`, "success", viewButton);
  };

  const showErrorNotification = () => {
    showNotification("Import failed.", "error");
  };

  const showInfoNotification = () => {
    showNotification("No numbers found.", "info");
  };

  // ==== RETURN ==== //
  return {
    importState,
    setImportState,
    showSuccessNotification,
    showErrorNotification,
    showInfoNotification,
    showNotification,
  };
};
