  import { generateContactSyncDetails } from "../ContactSyncDetails/utils/generateContactSyncDetials";
  
  // Used in ContactSyncHyperlink.tsx, IntegrationIdLink.tsx, 
  export const getContactSyncId = (integration: any) => {
    const contactType = Object.keys(integration)[0];
    const contactSyncDetails = generateContactSyncDetails(integration[contactType]);
    if (!contactSyncDetails.id) {
      return "";
    }
    return contactSyncDetails.id;
};
