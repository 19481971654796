import { useState } from "react";
import {
  Box,
  IconButton,
  ListItem,
  ListItemText,
  TextField,
  Autocomplete,
  styled,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { SequenceDropdownSearchBarProps } from "./types";

const StyledAutocomplete = styled(Autocomplete)`
  flex: 1 1 100%;

  fieldset {
    border: none;
  }
`;

const StyledIconButton = styled(IconButton)`
  svg {
    font-size: 24px;
  }
`;

function SequenceDropdownSearchBar({
  sequences,
  setSequenceId,
  fieldName = "sequenceId",
  setFieldValue,
  searchWidth = "546px",
}: SequenceDropdownSearchBarProps) {
  const [searchTerm, setSearchTerm] = useState("");

  const setSequence = (_event, newValue) => {
    setSequenceId(newValue.id);
    setFieldValue(fieldName, newValue.id);
  };

  const handleSearch = (_event, newInputValue) => {
    setSearchTerm(newInputValue);
  };

  const secondaryText = (sequence): string => {
    const sequenceType =
      sequence.scheduleType === "relative" ? "Ongoing" : "Scheduled";
    const stepCount =
      sequence.stepCount === 1
        ? `${sequence.stepCount} step`
        : `${sequence.stepCount} steps`;
    return `${sequenceType} | ${sequence.engagementRate}% engagement | ${sequence.pendingCount} pending | ${stepCount}`;
  };

  return (
    <Box
      sx={{
        width: searchWidth,
        height: "56px",
        borderRadius: "4px",
        border: "1px solid #0000001F",
        display: "flex",
      }}
    >
      <Box
        sx={{
          width: "fit-content",
          height: "55px",
          display: "inline-flex",
          flex: "0 0 100%",
        }}
      >
        <StyledAutocomplete
          autoFocus
          aria-label="Search"
          onChange={setSequence}
          onInputChange={handleSearch}
          slotProps={{
            listbox: {
              sx: {
                "&::-webkit-scrollbar": { display: "none" },
                height: "fit-content",
                padding: "0",
              },
            },
          }}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                placeholder="Search or select a sequence"
                autoFocus
                aria-label="Search"
                value={searchTerm}
                slotProps={{
                  input: {
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start" sx={{ margin: "0px" }}>
                        <StyledIconButton
                          aria-label="Search Button"
                          data-testid="search-button"
                          sx={{ paddingRight: "4px", paddingLeft: "8px" }}
                        >
                          <SearchIcon />
                        </StyledIconButton>
                      </InputAdornment>
                    ),
                  },
                }}
              />
            );
          }}
          renderOption={({ key, ...props }, option) => {
            return (
              <ListItem
                {...props}
                key={key}
                sx={{ "&.MuiListItem-root": { padding: "12px 16px" } }}
              >
                <ListItemText
                  primary={option.name}
                  secondary={secondaryText(option)}
                  sx={{
                    margin: "0",
                    padding: "0",
                  }}
                />
              </ListItem>
            );
          }}
          options={sequences}
        />
      </Box>
    </Box>
  );
}

export { SequenceDropdownSearchBar };
