interface GerneralContactSyncData {
  id?: string;
  status?: string;
  application?: {
    current_stage?: {
      name: string;
    }[];
    job?: {
      name: string;
    }[];
  };
  stage?: string;
  owner?: string;
  title?: string;
  birthday?: string;
  locations?: string[] | any[];
  emails?: string | string[];
}

const salesforceData = {
  id: "sid",
  status: "status",
  // No application property
  // No stage property
  owner: "owner",
  title: "title",
  birthday: "date_of_birth",
  locations: "location",
  emails: "email",
};

const workdayStudentData = {
  id: "student_id",
  status: "status",
  // No application property
  stage: "academic_level",
  // No owner property
  title: "Educational_Credential_ID", // Is this provided by BE?
  birthday: "Date_of_Birth", // Is this provided by BE?
  locations: "address",
  emails: "email",
};

/* 
This function is used to gerneralize the various data shapes we get back from each contact sync provider.
Here is a reference to the data matrix for contact sync providers besides Bullhorn and Greenhouse:
https://www.notion.so/textus/Merge-Implementation-a902d8a5e04b4a08b9601edbf79e79cf#5f18198918f241b1878cda7b92e301ad
This will need to be updated if we add more contact sync providers that have different data shapes.
*/
export const generateContactSyncDetails = (contactSyncData: any) => {
  const details = {} as GerneralContactSyncData;
  const dataKeys = Object.keys(contactSyncData);
  dataKeys.forEach((key) => {
    if (
      ["remote_id", "id", salesforceData.id, workdayStudentData.id].includes(
        key,
      )
    ) {
      details.id = contactSyncData[key];
    }
    if (
      [
        "employment_status",
        salesforceData.status,
        workdayStudentData.status,
      ].includes(key)
    ) {
      details.status = contactSyncData[key];
    }
    if (["application"].includes(key)) {
      [details.application] = contactSyncData[key];
    }
    if ([workdayStudentData.stage].includes(key)) {
      details.stage = contactSyncData[key];
    }
    if (["credited_to", "owner", "manager_id"].includes(key)) {
      details.owner = contactSyncData[key];
    }
    if (["title", "job_title", workdayStudentData.title].includes(key)) {
      details.title = contactSyncData[key];
    }
    if (["date_of_birth", workdayStudentData.birthday].includes(key)) {
      details.birthday = contactSyncData[key];
    }
    if (
      [
        "locations",
        "addresses",
        "location",
        workdayStudentData.locations,
      ].includes(key)
    ) {
      details.locations = contactSyncData[key];
    }
    if (
      [
        "emails",
        "email_address",
        "work_email",
        salesforceData.emails,
        workdayStudentData.emails,
      ].includes(key)
    ) {
      details.emails = contactSyncData[key];
    }
  });
  return details;
};