export const supportedIntegrations = [
  {
    provider: "bullhorn",
    providerName: "Bullhorn",
  },
  {
    provider: "greenhouse",
    providerName: "Greenhouse",
  },
  { provider: "salesforce", providerName: "Salesforce" },
  {
    provider: "sap_successfactors.merge_hris",
    providerName: "SAP SuccessFactors: HRIS",
  },
  {
    provider: "sap_successfactors.merge_ats",
    providerName: "SAP SuccessFactors: ATS",
  },
  {
    provider: "workday_student",
    providerName: "Workday Student",
  },
];
