import { useCallback, useMemo } from "react";
import Box from "@mui/material/Box";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import { IconButton, Stack } from "@mui/material";
import { clamp } from "lodash";
import { Props } from "./types";
import ViewMoreRow from "features/AccountManagement/ViewMoreRow";
import Logo from "components/Logo";

const getPhoneText = (phoneNumbers: any) => {
  if (phoneNumbers.length === 0) {
    return "Messaging not enabled";
  }
  if (phoneNumbers.length === 1) {
    return phoneNumbers[0];
  }
  return `${phoneNumbers.length} Numbers`;
};

export function Node({
  data,
  isOpen,
  style,
  toggle,
  dispatcher,
  confirm,
}: Props) {
  const {
    accountHierarchyId,
    children,
    effectiveRole,
    fetchAccountCollectionRequest,
    fetchAccountHierarchyRequest,
    id,
    name,
    nestingLevel,
    phoneNumbers,
    setCurrentAccount,
    type,
    users,
  } = data;

  const childrenId = children?.id;
  const totalCount = data?.childrenCount || 0;
  const isLeaf = totalCount === 0;
  const isClosed = children?.members.length !== totalCount && totalCount < 30;
  const toggleHandler = useCallback(() => {
    if (nestingLevel > 0 || type !== "partner") {
      fetchAccountCollectionRequest(childrenId);
    } else {
      fetchAccountHierarchyRequest(accountHierarchyId);
    }
    toggle();
  }, [
    accountHierarchyId,
    childrenId,
    fetchAccountCollectionRequest,
    fetchAccountHierarchyRequest,
    nestingLevel,
    toggle,
    type,
  ]);

  const icon = useMemo(() => {
    const showLoader = isOpen && children?.members.length === 0;
    if (showLoader && !isClosed)
      return (
        <Logo
          key={id}
          animate
          color="transparent"
          dotColor="currentColor"
          width={18}
        />
      );
    if (isOpen && !isClosed) return <ExpandMoreIcon key={id} />;
    return <ChevronRightIcon />;
  }, [children?.members.length, id, isClosed, isOpen]);

  if (type === "load_more") {
    // @ts-expect-error Type issue with data
    return <ViewMoreRow key={id} data={data} style={style} />;
  }

  return (
    <ListItem
      component="div"
      style={{
        ...style,
        backgroundColor:
          data.index % 2 === 0 ? "transparent" : "rgba(0, 0, 0, 0.04)",
        height: "52px",
        padding: 0,
        paddingLeft: `${clamp(nestingLevel * 32, 0, 32 * 4) + 16}px`,
      }}
      key={id}
    >
      {isLeaf ? (
        <Box width="32px" />
      ) : (
        <ListItemIcon onClick={toggleHandler}>{icon}</ListItemIcon>
      )}
      <ListItemText
        sx={{
          padding: 0,
        }}
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          <Box
            key="name"
            style={{
              flex: "auto",
              flexGrow: 1,
              flexShrink: 1,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              textAlign: "left",
            }}
            onClick={toggleHandler}
            component="button"
          >
            {name}
          </Box>

          <Box
            key="numbers"
            style={{
              flex: "192px",
              flexGrow: 0,
              flexShrink: 0,
              textAlign: "left",
            }}
          >
            {getPhoneText(phoneNumbers)}
          </Box>
          <Box
            key="children"
            style={{
              flex: "96px",
              flexGrow: 0,
              flexShrink: 0,
              textAlign: "left",
            }}
          >
            {totalCount}
          </Box>
          <Box
            key="users"
            style={{
              flex: "72px",
              flexGrow: 0,
              flexShrink: 0,
              textAlign: "left",
            }}
          >
            {users.totalItems ?? 0}
          </Box>
          <Box
            key="manage"
            style={{
              flex: "112px",
              flexGrow: 0,
              flexShrink: 0,
              textAlign: "center",
            }}
          >
            {effectiveRole && (
              <IconButton
                size="small"
                color="primary"
                onClick={() => {
                  return setCurrentAccount(id);
                }}
                aria-label="View"
              >
                <OpenInNewIcon />
              </IconButton>
            )}
            <IconButton
              size="small"
              color="primary"
              onClick={confirm(data.name, (result) => {
                if (result) dispatcher.dispatch("delete", id);
              })}
              aria-label={`Delete ${data.name}`}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </Stack>
      </ListItemText>
    </ListItem>
  );
}
